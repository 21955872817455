import { MIN_LIMIT, MIN_OFFSET } from '@components/Pagination';
import { DEFAULT_LIMIT, MAX_LIMIT } from '@components/Pagination/constants';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDefaultPagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const offset = searchParams.get('offset');
  const limit = searchParams.get('limit');

  useEffect(() => {
    if (offset === null || limit === null) {
      const offset = Math.max(MIN_OFFSET, +(searchParams.get('offset') ?? MIN_OFFSET));

      const initialLimit = searchParams.get('limit');
      const limit =
        initialLimit === null ? DEFAULT_LIMIT : Math.max(MIN_LIMIT, Math.min(+(initialLimit ?? MIN_LIMIT), MAX_LIMIT));

      setSearchParams(
        {
          ...Object.fromEntries([...searchParams]),
          offset: offset.toString(),
          limit: limit.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [offset, limit]);
};
