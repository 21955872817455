import { useDefaultPagination } from '@/hooks/useDefaultPagination';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import AddToListPopup from '../components/AddToListPopup';
import CompaniesTable from '../components/CompaniesTable';
import Filters from '../components/Filters';

const SimilarCompanies = ({ formData, setFormData, go, response, setPage, setPageSize }) => {
  const navigate = useNavigate();

  const [selectedIds, setSelectedIds] = useState([]);
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  useDefaultPagination();

  return (
    <div className="w-full">
      {showAddToListPopup && (
        <AddToListPopup handleModalToggle={() => setShowAddToListPopup(false)} companiesIds={selectedIds} />
      )}
      <div className="relative w-full">
        <div className="absolute right-0 z-[1000] flex h-full items-center bg-base-100 pl-5">
          <button className="btn btn-primary" onClick={(event) => go(event)}>
            Apply
          </button>
        </div>
        <Filters formData={formData} setFormData={setFormData} />
      </div>

      {response && response.data.data.length > 0 ? (
        <CompaniesTable
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          setPage={setPage}
          setPageSize={setPageSize}
          response={response?.data}
          size={'small'}
          displaySimilarityScore={true}
          pagination={false}
          forceReload={true}
          scoreHeaderText="Similarity Score"
        />
      ) : (
        <div className="card-elevation flex w-full flex-col items-center justify-start gap-5 bg-center p-40">
          <h2 className="text-3xl font-bold text-base-content lg:text-5xl">Ops...</h2>
          <p className="mb-4 text-lg">It seems like we haven't any companies meeting your criteria.</p>
          <button className="btn btn-primary" onClick={goBack}>
            Go back
          </button>
        </div>
      )}

      {/* Manipulate Selected */}
      {selectedIds.length > 0 && (
        <div className="fixed bottom-20 left-1/2 flex w-[60%] -translate-x-1/2 transform flex-col items-center justify-center rounded-lg bg-base-100 py-3 shadow-md sm:flex-row md:gap-4 lg:w-fit lg:px-5">
          <div className="">{selectedIds.length} selected</div>
          <div className="text-2xl font-thin text-neutral">|</div>
          <button className="btn btn-ghost" onClick={() => setShowAddToListPopup(true)}>
            <FaPlus className="bg-clip-text text-purple-500" />
            <span className="bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
              Add to my Lists
            </span>
          </button>
          {/* <button className="btn btn-ghost">
                <MdOutlineDriveFileMove size={22} className="bg-clip-text text-purple-500 " />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500">
                  Move to other List
                </span>
              </button> */}
        </div>
      )}
    </div>
  );
};

export default SimilarCompanies;
