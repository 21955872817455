import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';
import { TileLayout } from '../TileLayout';
import {
  InfoCardBody,
  InfoCardContent,
  InfoCardContentItem,
  InfoCardContentItemContent,
  InfoCardDescription,
  InfoCardDescriptionItem,
  InfoCardFooter,
  InfoCardFooterIcon,
  InfoCardHeader,
} from './parts';

type InfoCardProps = { className?: string } & PropsWithChildren;

export function InfoCard({ className, children }: InfoCardProps) {
  return <TileLayout className={cn('min-h-[266px] min-w-[366px] gap-1.5 px-3 py-2', className)}>{children}</TileLayout>;
}

InfoCard.Header = InfoCardHeader;
InfoCard.Body = InfoCardBody;
InfoCard.Description = InfoCardDescription;
InfoCard.DescriptionItem = InfoCardDescriptionItem;
InfoCard.Content = InfoCardContent;
InfoCard.ContentItem = InfoCardContentItem;
InfoCard.ContentItemContent = InfoCardContentItemContent;
InfoCard.Footer = InfoCardFooter;
InfoCard.FooterIcon = InfoCardFooterIcon;
