import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { SignalsPageProvider } from './context';
import { Filters, SignalsList } from './parts';

export const SignalsPage = () => {
  return (
    <SignalsPageProvider>
      <div className="flex flex-col gap-[14px]">
        <header className="flex w-full items-center justify-between">
          <Typography text={'signal_other'} variant="doubleXl/bold" tag="h1" className="capitalize text-grey" />
          <div className="flex gap-2 *:w-[168px]">
            <Button text="trigger_other" intent="secondary" />
            <Button text="report" intent="accent" />
          </div>
        </header>
        <div className="flex grow flex-col gap-[15px]">
          <Filters />
          <SignalsList />
        </div>
      </div>
    </SignalsPageProvider>
  );
};
