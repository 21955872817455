import { SidebarProvider } from '@/contexts/SidebarContext';
import { AuthenticatedTemplate } from '@global/authentication/components/AuthenticatedTemplate';
import { FC } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import { GlobalLoadingFallback } from './GlobalLoadingFallback';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

export const Layout: FC = () => {
  const navigation = useNavigation();

  return (
    <AuthenticatedTemplate>
      <div className="flex h-dvh flex-col overflow-hidden">
        <SidebarProvider>
          <Header />
          <div className="flex h-main shrink-0 overflow-y-hidden">
            <Sidebar />
            <main className="size-full overflow-y-auto p-4 ps-sidebar-short peer-aria-expanded:ps-sidebar-full motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none">
              {navigation.state === 'loading' ? <GlobalLoadingFallback /> : <Outlet />}
            </main>
          </div>
        </SidebarProvider>
      </div>
      <div id="global-modals-container" />
    </AuthenticatedTemplate>
  );
};
