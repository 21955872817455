import axios from 'axios';
import { customAxios } from './customAxios';

const PATH = '/api/accounts';
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

let axiosConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*', // Required for CORS support to work
    'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
  },
};

export function httpLogin(email, password) {
  return axios.post(`${API_ENDPOINT}${PATH}/login`, { email: email, password: password }, axiosConfig);
}

export function httpSignup(email, password, firstName, lastName) {
  return axios.post(`${API_ENDPOINT}${PATH}/register`, {
    email: email,
    password: password,
    firstName: firstName,
    lastName: lastName,
  });
}

export function httpResetPassword(email) {
  return axios.post(`${import.meta.env.VITE_API_ENDPOINT}${PATH}/reset-password`, { email: email });
}

// export function httpGetUserInfo() {
//   const userId = localStorage.getItem('userId');

//   return customAxios.get(`${API_ENDPOINT}${PATH}/${userId}`);
// }

export function httpGetUserInfo() {
  const userId = localStorage.getItem('userId');

  return customAxios.get(`https://2b9eae54-5223-49d6-a528-c1b225d291a9.mock.pstmn.io/resource`);
}

export function httpGoogleAuth(accessToken, refreshToken, firstName, lastName) {
  return axios.post(
    `${API_ENDPOINT}${PATH}/google`,
    {
      refreshToken,
      firstName,
      lastName,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
}
