import { useCompany } from '@/api/entities/companies';
import { useTeamMembers } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { NoTeamDataCard, TeamMembersList, TeamMetrics } from './parts';

export const TeamBoardMembersTab: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { company } = useCompany({
    companyId: id as string,
    signal,
  });

  const { isEmpty, isFetched } = useTeamMembers({
    companyId: id as string,
    companyName: company?.companyName,
    signal,
  });

  return isFetched && isEmpty ? (
    <NoTeamDataCard />
  ) : (
    <div className="flex h-[70dvh] flex-col gap-[22px] overflow-y-auto pe-2 outline-none">
      <TeamMetrics />
      <TeamMembersList />
    </div>
  );
};
