import { cn } from '@/lib/utils';
import { SvgIcon } from '@components/SvgIcon';
import { FC } from 'react';
import { RadioCardProps } from './types';

export const RadioCard: FC<RadioCardProps> = ({ id, checked, onChange, labelProps, inputProps, children }) => (
  <label
    {...labelProps}
    htmlFor={id}
    aria-selected={checked}
    className={cn(
      'group/radio-card flex cursor-pointer items-center justify-between bg-grey-100 px-4 py-[11px] drop-shadow aria-selected:bg-grey-300 hover:drop-shadow-lg motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none',
      labelProps?.className,
    )}
  >
    {children}
    {onChange ? (
      <div className="relative flex size-[18px] rounded-full bg-gradient-to-r from-gradient-from to-gradient-to p-[1px] has-[:checked]:p-[9px] group-hover/radio-card:p-[9px] motion-safe:transition-all motion-safe:duration-300 motion-reduce:transition-none">
        <input
          {...inputProps}
          id={id}
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(id, e)}
          className={cn('peer size-full appearance-none rounded-full bg-white', inputProps?.className)}
        />
        <SvgIcon
          name="Checkmark"
          className="absolute left-0 top-0 opacity-0 motion-safe:translate-y-full motion-safe:transition-all motion-safe:duration-200 motion-safe:peer-checked:animate-check motion-reduce:peer-checked:opacity-100"
        />
      </div>
    ) : null}
  </label>
);
