import { NavLink } from 'react-router-dom';

function ComingSoonPage() {
  return (
    <div className="card-elevation flex w-full flex-col items-center justify-start gap-5 bg-center p-40">
      <h2 className="text-3xl font-bold text-base-content lg:text-5xl">Coming Soon!</h2>
      <p className="mb-4 text-lg">We're working hard to bring you a great experience. Stay tuned!</p>
      <NavLink to="/home" className="btn btn-primary">
        Go back to Home
      </NavLink>
    </div>
  );
}

export default ComingSoonPage;
