import { FC } from 'react';
import { Password } from './parts/Password/Password';
import { PersonalInformation } from './parts/PersonalInformation/PersonalInformation';

export const UserDetails: FC = () => (
  <div className="flex h-full w-[560px] flex-col gap-4">
    <PersonalInformation />
    <Password />
  </div>
);
