import { api } from '@/api/apiV2';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseCompanyArgs = {
  companyId: string;
  signal: AbortSignal;
};

export const useCompany = ({ companyId, signal }: UseCompanyArgs) => {
  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.Companies, companyId],
    queryFn: async () => {
      const response = await api.company.getById(companyId, {
        signal,
      });

      return response.data;
    },
  });

  return {
    company: data,
    ...rest,
  };
};
