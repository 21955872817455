import { cn } from '@/lib/utils';
import { LocalizedText, Typography } from '@components/Typography';
import { ArbitraryText } from '@components/Typography/types';

type InfoCardDescriptionItemProps = (LocalizedText | ArbitraryText) & { className?: string };

export function InfoCardDescriptionItem({ className, ...rest }: InfoCardDescriptionItemProps) {
  return (
    <Typography
      {...rest}
      variant="xs/regular"
      className={cn(
        'shrink truncate leading-[14px] text-grey-700 first-letter:capitalize first:shrink-0 last:shrink-0',
        className,
      )}
    />
  );
}
