import { cn } from '@/lib/utils';
import { LocalizedText, Typography } from '@components/Typography';
import { PropsWithChildren } from 'react';

type InfoCardContentItemProps = {
  title: LocalizedText;
  className?: string;
} & PropsWithChildren;

export function InfoCardContentItem({ title, className, children }: InfoCardContentItemProps) {
  return (
    <div className={cn('flex flex-col *:leading-[18px] *:text-grey-700 *:first-letter:capitalize', className)}>
      <Typography {...title} variant="xs/semibold" className="shrink-0" />
      {children}
    </div>
  );
}
