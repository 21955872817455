import { cn } from '@/lib/utils';
import { TableElement } from '@components/Tables/types';
import { type Header, flexRender } from '@tanstack/react-table';
import { makeAccessible } from '@utils';
import { useTranslation } from 'react-i18next';

type TableHeaderCellProps<T extends TableElement> = {
  header: Header<T, unknown>;
  onHeaderClick: (e: React.MouseEvent, header: Header<T, unknown>) => void;
  classNames?: {
    thClassName?: string;
    contentWrapperClassName?: string;
  };
};

export const TableHeaderCell = <T extends TableElement>({
  header,
  onHeaderClick,
  classNames: { thClassName, contentWrapperClassName } = {},
}: TableHeaderCellProps<T>) => {
  const { t } = useTranslation();
  const headerContent = flexRender(header.column.columnDef.header, header.getContext());
  const headerRendered = typeof headerContent === 'string' ? t(headerContent) : headerContent;
  const sortingDirection = typeof header.column.getIsSorted() === 'boolean' ? 'empty' : header.column.getIsSorted();
  const isAsc = sortingDirection === 'asc';

  return (
    <th
      key={header.id}
      {...makeAccessible((e) => onHeaderClick(e, header))}
      style={{
        maxWidth: header.column.columnDef.maxSize,
        minWidth: header.column.columnDef.minSize,
        width: header.column.columnDef.size,
      }}
      className={cn(
        'group/tableHeader border border-transparent p-0 text-xs font-semibold capitalize leading-none text-grey-700 first:rounded-bl-lg last:rounded-br-lg',
        thClassName,
      )}
    >
      <div className={cn('flex h-[100%] items-center gap-x-1 pl-1', contentWrapperClassName)}>
        <div
          className={cn(
            'flex h-[100%] flex-1 py-[10px] pl-1',
            header.column.getCanSort() ? 'group-hover/tableHeader:bg-outline cursor-pointer' : 'cursor-auto',
          )}
        >
          <div className={'flex h-[100%] flex-1 select-none items-center gap-4'}>
            <div>{headerRendered}</div>
            {header.column.getCanSort() && (
              <div className="flex flex-col items-center justify-center gap-1">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m4.138 2.738 1.968 1.968a.797.797 0 1 0 1.128-1.128L4.698 1.034a.797.797 0 0 0-1.128 0L1.034 3.578a.797.797 0 1 0 1.128 1.128z"
                    className={`${sortingDirection === 'empty' ? 'fill-[#E1E5ED]' : isAsc ? 'fill-[#7D8FB3]' : 'fill-[#E1E5ED]'}`}
                  />
                </svg>
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  transform="matrix(1,-1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
                >
                  <path
                    d="m4.138 2.738 1.968 1.968a.797.797 0 1 0 1.128-1.128L4.698 1.034a.797.797 0 0 0-1.128 0L1.034 3.578a.797.797 0 1 0 1.128 1.128z"
                    className={`${sortingDirection === 'empty' ? 'fill-[#E1E5ED]' : !isAsc ? 'fill-[#7D8FB3]' : 'fill-[#E1E5ED]'}`}
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </th>
  );
};
