import { Typography } from '@components/Typography';
import { FC } from 'react';

type ErrorMessageProps = {
  message: string | undefined;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return message ? <Typography arbitrary text={message} variant="xs/regular" className="text-red-500" /> : null;
};
