/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddCompaniesToUserListRequest {
  companyIds?: string[] | null;
  userListIds?: string[] | null;
  /** @format uuid */
  userId?: string;
}

export interface BaseFilter {
  /** @format int32 */
  foundedFrom?: number | null;
  /** @format int32 */
  foundedTo?: number | null;
  /** @format double */
  scoreFrom?: number | null;
  /** @format double */
  scoreTo?: number | null;
  /** @format int32 */
  limit?: number;
  country?: string | null;
  companySize?: string[] | null;
  foundingStages?: string[] | null;
  isFoundingInfoExist?: boolean;
  isVectorSearch?: boolean;
}

export interface CompanyResponse {
  companyId?: string | null;
  companyName?: string | null;
  websiteUrl?: string | null;
  logoUrl?: string | null;
  countryName?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  productsServices?: string | null;
  targetClients?: string | null;
  targetMarkets?: string[] | null;
  ownershipType?: string | null;
  headquarters?: HeadquartersResponse;
  /** @format int32 */
  foundedIn?: number | null;
  foundingInfo?: FoundingInfoResponse;
  /** @format int64 */
  vatCode?: number | null;
  linkedinUrl?: string | null;
  l1Industry?: string | null;
  l2Industry?: string | null;
  liIdentifier?: string | null;
  liIndustry?: string | null;
  employeeInLinkedIn?: string | null;
  /** @format int32 */
  followers?: number | null;
  lastUpdateDate?: string | null;
  insertionDate?: string | null;
  /** @format double */
  score?: number;
  userLists?: string[] | null;
}

export interface CompanyResponsePaginatedResult {
  /** @format int64 */
  totalRecords?: number;
  data?: CompanyResponse[] | null;
}

export interface EducationResponse {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  subtitle: string;
  /** @minLength 1 */
  caption: string;
}

export interface ExperienceResponse {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  subtitle: string;
  /** @minLength 1 */
  caption: string;
}

export interface Filter {
  /** @format int32 */
  foundedFrom?: number | null;
  /** @format int32 */
  foundedTo?: number | null;
  /** @format double */
  scoreFrom?: number | null;
  /** @format double */
  scoreTo?: number | null;
  /** @format int32 */
  limit?: number;
  country?: string | null;
  companySize?: string[] | null;
  foundingStages?: string[] | null;
  isFoundingInfoExist?: boolean;
  isVectorSearch?: boolean;
  query?: string | null;
  title?: string | null;
  signalTypes?: SignalType[] | null;
  /** @format int32 */
  offset?: number;
}

export interface FirstListedInvestorResponse {
  name?: string | null;
}

export interface FoundingInfoResponse {
  totalRounds?: string | null;
  lastRound?: LastRoundResponse;
  firstListedInvestor?: FirstListedInvestorResponse;
}

export interface GoogleLoginRequest {
  refreshToken?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface HeadquartersResponse {
  fullAddress?: string | null;
  street?: string | null;
  additional?: string | null;
  directions?: string | null;
}

export interface LastRoundResponse {
  type?: string | null;
  date?: string | null;
  amountRaised?: string | null;
}

export interface NoteRequest {
  /** @format uuid */
  userId?: string;
  title?: string | null;
  content?: string | null;
}

export interface PaginatedSignalsGroupResponse {
  hasNextPage: boolean;
  signalsGroups: SignalsGroupResponse[];
}

export interface PeopleResponse {
  /** @minLength 1 */
  peopleId: string;
  /** @minLength 1 */
  companyId: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  fullName: string;
  /** @minLength 1 */
  publicIdentifier: string;
  /** @minLength 1 */
  headline: string;
  /** @format int32 */
  connections?: number;
  /** @format int32 */
  followers?: number;
  emailRequired?: boolean;
  openConnection?: boolean;
  urn?: string | null;
  /** @minLength 1 */
  addressCountryOnly: string;
  /** @minLength 1 */
  addressWithCountry: string;
  /** @minLength 1 */
  addressWithoutCountry: string;
  profileAvatar?: string | null;
  about?: string | null;
  /** @format int32 */
  age?: number | null;
  experiences: ExperienceResponse[];
  educations: EducationResponse[];
}

export interface PeopleResponseInfiniteQueryResponse {
  hasNextPage: boolean;
  data: PeopleResponse[];
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface QueryResponse {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string | null;
  userId?: string | null;
  query?: string | null;
  filter?: BaseFilter;
  /** @format int32 */
  countOfCompanies?: number;
  title?: string | null;
  signalTypes?: SignalType[] | null;
}

export interface RefreshTokenRequest {
  refreshToken?: string | null;
}

export interface SignalCompanyResponse {
  /** @minLength 1 */
  companyId: string;
  /** @minLength 1 */
  companyName: string;
  /** @minLength 1 */
  websiteUrl: string;
  /** @minLength 1 */
  linkedinUrl: string;
  /** @format int32 */
  foundedIn: number;
  /** @format double */
  score: number;
  headquarters?: HeadquartersResponse;
  foundingInfo?: FoundingInfoResponse;
  /** @minLength 1 */
  logoUrl: string;
}

export interface SignalResponse {
  /** @format uuid */
  id: string;
  signalType: SignalType;
  /** @format double */
  score: number;
  company: SignalCompanyResponse;
  /** @minLength 1 */
  body: string;
}

export enum SignalSorting {
  Unknown = 'Unknown',
  MostRecent = 'MostRecent',
  FromOlderToNewer = 'FromOlderToNewer',
}

export enum SignalType {
  Unknown = 'Unknown',
  NewCompanies = 'NewCompanies',
}

export interface SignalsGroupResponse {
  /** @format date-time */
  date: string;
  signals: SignalResponse[];
}

export interface TeamMemberNationality {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  count: number;
}

export interface TeamMetrics {
  /** @format int32 */
  averageAge: number;
  /** @format int32 */
  minAge: number;
  /** @format int32 */
  maxAge: number;
  nationalities: TeamMemberNationality[];
}

export interface UpdateQuerySignalTypesRequest {
  signalTypes?: SignalType[] | null;
}

export interface UpdateUserRequest {
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
}

export interface UserListRequest {
  /** @format uuid */
  userId?: string;
  name?: string | null;
  notes?: string | null;
  companiesIds?: string[] | null;
}

export interface UserListUpdateRequest {
  toAdd?: string[] | null;
  toDelete?: string[] | null;
  notes?: string | null;
  name?: string | null;
}

export interface UserRequest {
  email?: string | null;
  password?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  profileImage?: string | null;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>(
    { secure, path, type, query, format, body, ...params }: FullRequestParams,
  ): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title YunoAI.API
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  accounts = {
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRegisterCreate
     * @request POST:/api/accounts/register
     */
    accountsRegisterCreate: (data: UserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLoginCreate
     * @request POST:/api/accounts/login
     */
    accountsLoginCreate: (data: UserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsResetPasswordCreate
     * @request POST:/api/accounts/reset-password
     */
    accountsResetPasswordCreate: (data: UserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsList
     * @request GET:/api/accounts
     */
    accountsList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsUpdate
     * @request PUT:/api/accounts
     */
    accountsUpdate: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRefreshTokenCreate
     * @request POST:/api/accounts/refresh-token
     */
    accountsRefreshTokenCreate: (data: RefreshTokenRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/refresh-token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGoogleCreate
     * @request POST:/api/accounts/google
     */
    accountsGoogleCreate: (data: GoogleLoginRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/google`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  company = {
    /**
     * No description
     *
     * @tags Company
     * @name CompaniesSearchCreate
     * @request POST:/api/companies/search
     */
    companiesSearchCreate: (data: Filter, params: RequestParams = {}) =>
      this.http.request<CompanyResponsePaginatedResult, any>({
        path: `/api/companies/search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name GetById
     * @request GET:/api/companies/{id}
     */
    getById: (id: string, params: RequestParams = {}) =>
      this.http.request<CompanyResponse, ProblemDetails>({
        path: `/api/companies/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesSimilarCompaniesCreate
     * @request POST:/api/companies/{companyId}/similar-companies
     */
    companiesSimilarCompaniesCreate: (companyId: string, data: Filter, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${companyId}/similar-companies`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesDetail
     * @request GET:/api/companies/{id}/notes
     */
    companiesNotesDetail: (
      id: string,
      query?: {
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesCreate
     * @request POST:/api/companies/{id}/notes
     */
    companiesNotesCreate: (id: string, data: NoteRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesUpdate
     * @request PUT:/api/companies/{id}/notes/{noteId}
     */
    companiesNotesUpdate: (id: string, noteId: string, data: NoteRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes/${noteId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesDelete
     * @request DELETE:/api/companies/{id}/notes/{noteId}
     */
    companiesNotesDelete: (id: string, noteId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes/${noteId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesSearchInListsCreate
     * @request POST:/api/companies/search-in-lists
     */
    companiesSearchInListsCreate: (data: Filter, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/search-in-lists`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags Documents
     * @name DocumentsProfileImageUpdate
     * @request PUT:/api/documents/profile-image
     */
    documentsProfileImageUpdate: (
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        /** @format int64 */
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/documents/profile-image`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name DocumentsProfileImageList
     * @request GET:/api/documents/profile-image
     */
    documentsProfileImageList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/documents/profile-image`,
        method: 'GET',
        ...params,
      }),
  };
  query = {
    /**
     * No description
     *
     * @tags Query
     * @name QueriesList
     * @request GET:/api/queries
     */
    queriesList: (params: RequestParams = {}) =>
      this.http.request<QueryResponse[], any>({
        path: `/api/queries`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Query
     * @name QueriesDelete
     * @request DELETE:/api/queries/{id}
     */
    queriesDelete: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/queries/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Query
     * @name UpdateQuerySignalTypes
     * @request PATCH:/api/queries/{id}
     */
    updateQuerySignalTypes: (id: string, data: UpdateQuerySignalTypesRequest, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails>({
        path: `/api/queries/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  signal = {
    /**
     * No description
     *
     * @tags Signal
     * @name GetSignals
     * @request GET:/api/signals
     */
    getSignals: (
      query?: {
        Type?: SignalType;
        Sorting?: SignalSorting;
        /** @format int32 */
        Offset?: number;
        ShowDuplicates?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PaginatedSignalsGroupResponse, any>({
        path: `/api/signals`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signal
     * @name DeleteSignalById
     * @request DELETE:/api/signals/{id}
     */
    deleteSignalById: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/signals/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  teams = {
    /**
     * No description
     *
     * @tags Teams
     * @name GetCompanyTeamMembers
     * @request GET:/api/companies/{companyId}/team
     */
    getCompanyTeamMembers: (
      companyId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PeopleResponseInfiniteQueryResponse, any>({
        path: `/api/companies/${companyId}/team`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name GetCompanyTeamMetrics
     * @request GET:/api/companies/{companyId}/team/metrics
     */
    getCompanyTeamMetrics: (companyId: string, params: RequestParams = {}) =>
      this.http.request<TeamMetrics, any>({
        path: `/api/companies/${companyId}/team/metrics`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  userList = {
    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCreate
     * @request POST:/api/user-lists
     */
    userListsCreate: (data: UserListRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsList
     * @request GET:/api/user-lists
     */
    userListsList: (
      query?: {
        /** @format uuid */
        userId?: string;
        /** @format int32 */
        Limit?: number;
        /** @format int32 */
        Offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesCreate
     * @request POST:/api/user-lists/companies
     */
    userListsCompaniesCreate: (data: AddCompaniesToUserListRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/companies`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesList
     * @request GET:/api/user-lists/companies
     */
    userListsCompaniesList: (
      query?: {
        /** @format int32 */
        offset?: number;
        /** @format int32 */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists/companies`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsUpdate
     * @request PUT:/api/user-lists/{id}
     */
    userListsUpdate: (id: string, data: UserListUpdateRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsDelete
     * @request DELETE:/api/user-lists/{id}
     */
    userListsDelete: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsDetail
     * @request GET:/api/user-lists/{id}
     */
    userListsDetail: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesDetail
     * @request GET:/api/user-lists/{id}/companies
     */
    userListsCompaniesDetail: (
      id: string,
      query?: {
        /** @format int32 */
        offset?: number;
        /** @format int32 */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}/companies`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesCheckList
     * @request GET:/api/user-lists/companies/check
     */
    userListsCompaniesCheckList: (
      query?: {
        /** @format uuid */
        userId?: string;
        companyId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists/companies/check`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
}
