import { useContext } from 'react';
import { SignalsActionsContext } from './context';

export const useSignalsPageActions = () => {
  const context = useContext(SignalsActionsContext);

  if (!context) {
    throw new Error('useSignalsPageActions must be used within a SignalsPageProvider');
  }

  return context;
};
