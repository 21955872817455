import { MIN_LIMIT } from '@components/Pagination';
import type { ColumnDef } from '@tanstack/react-table';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import type { ForwardedRef, ReactElement, Ref } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';
import { onSortingChangeHandler } from '../helpers';
import { TablePagination } from '../parts/Pagination/TablePagination';
import { PaginationControlState } from '../parts/Pagination/types';
import { selectionColumn } from '../parts/TableCheckbox/SelectionColumn';
import { baseTableReducer, TableStateActions } from '../reducer';
import { TableBase } from '../TableBase/TableBase';
import { TableBaseAPI } from '../TableBase/types';
import { ServerTableAPI, ServerTableViewProps } from '../types';
import { useTableQuery } from '../useTableQuery';

const ServerTableComp = <T extends { id?: string }>(
  {
    id,
    initialState,
    queryKey,
    afterFetchHook = () => null,
    fetcher,
    rowSelection = {},
    onRowSelectionChange,
    enableMultiRowSelection = false,
    usePagination,
    ...props
  }: ServerTableViewProps<T>,
  ref: Ref<ServerTableAPI<T>>,
) => {
  const [state, dispatch] = useReducer(baseTableReducer<T>, {
    totalRecords: 0,
    tableData: [],
    limit: MIN_LIMIT,
    offset: 0,
    ...initialState,
  });

  const { columns, tableData, sorting, totalRecords, offset, limit } = state;

  const { data, isLoading } = useTableQuery<T>({
    queryKey,
    fetcher,
    sorting: sorting?.[0],
    afterFetchHook,
    offset,
    limit,
  });

  const paginationState: PaginationControlState = {
    offset,
    totalRecords: data?.totalRecords ?? 0,
    limit,
  };

  const tableRef = useRef<TableBaseAPI>(null);

  useEffect(() => {
    dispatch({
      type: TableStateActions.UPDATE_AFTER_FETCH,
      payload: data?.data ?? [],
      totalRecords: data?.totalRecords ?? 0,
    });
  }, [data]);

  useLayoutEffect(() => {
    if (usePagination) {
      tableRef.current?.scrollToOffset?.(0);
    }
  }, [limit, offset]);

  const enableRowSelection = !!rowSelection && !!onRowSelectionChange;

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection,
    enableMultiRowSelection,
    onRowSelectionChange,
    onSortingChange: onSortingChangeHandler(sorting, tableRef, dispatch, onRowSelectionChange),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    manualSorting: true,
    sortDescFirst: false,
    defaultColumn: {
      minSize: 50,
      size: 600,
    },
    getRowId: (row) => row.id!,
  });

  useEffect(() => {
    if (enableRowSelection) {
      dispatch({
        type: TableStateActions.UPDATE_SELECTED_ROWS,
        payload: [selectionColumn<T>({ table, enableMultiRowSelection }), ...initialState.columns] as ColumnDef<T>[],
      });
    }
  }, [enableRowSelection, table.getIsAllPageRowsSelected()]);

  useImperativeHandle(ref, () => ({
    dispatch,
    state,
    tableApi: table,
  }));

  return (
    <TableBase<T>
      id={id}
      ref={tableRef}
      table={table}
      isLoading={isLoading}
      footer={usePagination ? <TablePagination paginationState={paginationState} dispatch={dispatch} /> : null}
      enableRowSelection={enableRowSelection}
      {...props}
    />
  );
};

export const ServerTable = forwardRef(ServerTableComp) as <T extends { id?: string }>(
  props: ServerTableViewProps<T> & { ref?: ForwardedRef<ServerTableAPI<T>> },
) => ReactElement;
