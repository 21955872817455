import { PeopleEntity } from '@/api/entities/teams/types';
import { Typography } from '@components/Typography';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<PeopleEntity>();

export const EDUCATIONS_TABLE_COLUMNS = [
  // columnHelper.display({
  //   id: 'tier',
  //   header: 'tier',
  //   cell: (info) => 1,
  //   minSize: 50,
  //   maxSize: 50,
  //   meta: {
  //     className: () => 'font-bold',
  //   },
  //   enableSorting: false,
  // }),
  {
    accessorFn: (row) => row.educations[0]?.title?.toString() ?? '-',
    id: 'educations',
    header: 'education',
    cell: (info) => (
      <Typography
        arbitrary
        text={info.getValue()?.toString() ?? '-'}
        variant="sm/bold"
        className="truncate capitalize text-grey-900"
      />
    ),
    minSize: 200,
    maxSize: 200,
  },
  columnHelper.accessor('fullName', {
    id: 'fullName',
    header: 'person',
    cell: (info) => info.getValue(),
    minSize: 150,
    maxSize: 150,
    meta: {
      className: () => 'truncate',
    },
  }),
] as ColumnDef<PeopleEntity>[];
