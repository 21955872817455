import { BaseModal } from '@components/BaseModal/BaseModal';
import { Button } from '@components/Button';
import { MultiSelect } from '@components/MultiSelect';
import { RadioButton } from '@components/RadioButton';
import { Typography } from '@components/Typography';
import { SIGNAL_TYPES_OPTIONS } from '@global/constants/options';
import { Option } from '@global/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { ErrorMessage } from '../FormElements';
import { ModalFormField } from '../FormField';
import { saveQueryFormSchema, SaveQueryInput, SaveQueryOutput } from './validator';

type SaveQueryPopupProps = {
  handleClose: VoidFunction;
  handleSaveConfirm: (query: string, triggers: string[]) => Promise<void>;
};

const SaveQueryPopup: FC<SaveQueryPopupProps> = ({ handleClose, handleSaveConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    resetField,
    trigger,
    formState: { errors, touchedFields },
  } = useForm<SaveQueryInput, unknown, SaveQueryOutput>({
    resolver: zodResolver(saveQueryFormSchema),
    mode: 'onChange',
    defaultValues: {
      query: '',
      useTriggers: null,
      triggers: [],
    },
  });

  const onSubmit: SubmitHandler<SaveQueryOutput> = async (data) => {
    setIsLoading(true);
    await handleSaveConfirm(
      data.query,
      data.triggers.map((option) => option.value),
    );
    setIsLoading(false);
  };

  const useTriggers = useWatch({
    control,
    name: 'useTriggers',
  });

  useEffect(() => {
    resetField('triggers');
    trigger('triggers');
  }, [useTriggers]);

  return (
    <BaseModal isOpen onCloseHandler={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
        <Typography text={'saveSearchToMyArea'} tag="h2" variant="doubleXl/bold" className="text-grey" />
        <fieldset className="flex flex-col gap-10">
          <ModalFormField
            required
            title={{
              text: 'queryName',
            }}
          >
            <input
              {...register('query')}
              type="text"
              placeholder="Enter save name..."
              className="input input-bordered w-full"
            />
            <ErrorMessage message={errors.query?.message} />
          </ModalFormField>
          <div className="flex flex-col gap-3">
            <Typography
              text={'receiveSignalsWarning'}
              className="text-xs text-grey first-letter:capitalize after:ps-0.5 after:text-red-500 after:content-['*']"
            />
            <div className="flex gap-6">
              <Controller
                name="useTriggers"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioButton
                    id="signals-yes"
                    label={{
                      text: 'yes',
                    }}
                    checked={value === true}
                    onChange={(id) => onChange(id === 'signals-yes')}
                  />
                )}
              />
              <Controller
                name="useTriggers"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioButton
                    id="signals-no"
                    label={{
                      text: 'no',
                    }}
                    checked={value === false}
                    onChange={(id) => onChange(id === 'signals-yes')}
                  />
                )}
              />
            </div>
          </div>
          {useTriggers ? (
            <Controller
              name="triggers"
              control={control}
              render={({ field }) => (
                <ModalFormField
                  required
                  title={{
                    text: 'addSignalsTrigger',
                  }}
                >
                  <MultiSelect
                    options={SIGNAL_TYPES_OPTIONS}
                    selectedItems={field.value}
                    handleToggleItem={(value: Option<string>) => {
                      const newSelectedItems = field.value;
                      const index = newSelectedItems.findIndex((option) => option.value === value.value);

                      if (index !== -1) {
                        newSelectedItems.splice(index, 1);
                      } else {
                        newSelectedItems.push(value);
                      }

                      field.onChange(newSelectedItems);
                    }}
                    inputClassName="capitalize h-12"
                    optionLabelClassName="capitalize"
                  />
                  <ErrorMessage message={errors.triggers?.message} />
                </ModalFormField>
              )}
            />
          ) : null}
        </fieldset>
        <footer className="flex items-center justify-center gap-4">
          <Button
            type="submit"
            disabled={Object.keys(errors).length > 0 || Object.keys(touchedFields).length === 0 || useTriggers === null}
            isLoading={isLoading}
            className="w-1/2 items-center gap-2"
          >
            <FaSave size={16} />
            <Typography text="save" variant="sm/regular" className="capitalize" />
          </Button>
        </footer>
      </form>
    </BaseModal>
  );
};

export default SaveQueryPopup;
