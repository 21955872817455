import { useTeamMetrics } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { Loadable } from '@global/components/Loadable';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  AverageAgeMetric,
  EducationMetric,
  FoundersMarketFitMetric,
  TeamNationalities,
  TechnicalFiguresMetric,
} from './parts';

export const TeamMetrics: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { isFetching } = useTeamMetrics({
    companyId: id as string,
    signal,
  });

  return (
    <section className="grid grid-cols-[1fr_min-content] grid-rows-[151px] gap-[21px]">
      <TileLayout className="flex-row justify-between py-6">
        <Loadable isLoading={isFetching} spinnerProps={{ size: 'lg' }}>
          <AverageAgeMetric />
          <FoundersMarketFitMetric />
          <EducationMetric />
          <TechnicalFiguresMetric />
        </Loadable>
      </TileLayout>
      <TeamNationalities />
    </section>
  );
};
