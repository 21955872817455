import { cn } from '@/lib/utils';
import { IconName, SvgIcon } from '@components/SvgIcon';
import { ComponentProps } from 'react';

type InfoCardFooterIconProps = {
  icon: IconName;
  src?: string;
} & Omit<ComponentProps<'a'>, 'aria-disabled' | 'href' | 'target' | 'rel'>;

export function InfoCardFooterIcon({ icon, src, className, ...rest }: InfoCardFooterIconProps) {
  return (
    <a
      {...rest}
      aria-disabled={!src}
      href={src}
      target="_blank"
      rel="noreferrer"
      className={cn('group aria-disabled:opacity-30', className)}
    >
      <SvgIcon
        name={icon}
        className="delay-100 group-aria-disabled:cursor-not-allowed group-aria-[disabled=false]:hover:scale-125 motion-safe:transition-transform motion-safe:duration-200 motion-reduce:transition-none"
      />
    </a>
  );
}
