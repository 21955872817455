import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { apiURL } from '../api';
import { httpGetUserLists } from '../api/UserListsAPI';
const ListContext = createContext();

export function useLists() {
  return useContext(ListContext);
}

export const ListProvider = ({ children }) => {
  const [lists, setLists] = useState([]);

  const fetchLists = () => {
    httpGetUserLists()
      .then((response) => {
        setLists(response.data);
      })
      .catch((error) => {
        console.error('Error fetching lists:', error);
      });
  };

  const createList = async (name, user) => {
    if (lists.some((list) => list.name === name)) {
      alert('List with the same name already exists.');
      return;
    }
    try {
      const response = await axios.post(`${apiURL}/lists`, { name, user });
      setLists((prev) => [...prev, response.data]);
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const deleteList = async (id) => {
    try {
      await axios.delete(`${apiURL}/lists/${id}`);
      setLists((prev) => prev.filter((list) => list._id !== id));
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  const deleteSubList = async (listID, sublistID) => {
    try {
      await axios.delete(`${apiURL}/delete-sublist/${listID}/${sublistID}`);
      setLists((prevLists) => {
        return prevLists.map((list) => {
          if (list._id === listID) {
            list.subList = list.subList.filter((sublist) => sublist._id !== sublistID);
          }
          return list;
        });
      });
    } catch (error) {
      throw new Error(error.response.data.message || 'Something went wrong');
    }
  };

  const createSubList = async (listID, listName) => {
    const parentList = lists.find((list) => list._id === listID);

    if (parentList && parentList.subList.some((sublist) => sublist.listName === listName)) {
      alert('Sublist with the same name already exists in the parent list.');
      return;
    }

    try {
      const response = await axios.post(`${apiURL}/create-sub-list`, {
        listID,
        listName,
      });
      const updatedParentList = response.data;
      setLists((prevLists) => {
        const updatedLists = prevLists.map((list) => {
          if (list._id === updatedParentList._id) {
            return updatedParentList;
          }
          return list;
        });
        return updatedLists;
      });
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  return (
    <ListContext.Provider value={{ lists, fetchLists, createList, deleteList, deleteSubList, createSubList }}>
      {children}
    </ListContext.Provider>
  );
};
