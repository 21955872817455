import { api } from '@/api/apiV2';
import { UserRequest } from '@/api/generated/Api';
import { useMutation } from '@tanstack/react-query';

type UseUpdateUserArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: UserRequest) => void;
};

export const useResetPassword = ({ signal, onSuccess }: UseUpdateUserArgs) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['reset-password'],
    mutationFn: async (data: UserRequest) => {
      await api.accounts.accountsResetPasswordCreate(data, { signal });

      onSuccess?.(data);
    },
  });

  return {
    resetPassword: mutate,
    isRestingPassword: isPending,
  };
};
