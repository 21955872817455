import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import type { ForwardedRef, ReactElement, Ref } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { TableBase } from '../TableBase/TableBase';
import type { FrontTableAPI, TableElement } from '../types';

export type FrontTableViewProps<T extends TableElement> = {
  id: string;
  tableData: T[];
  columns: ColumnDef<T>[];
  initialSorting: SortingState;
  className?: string;
  isLoading?: boolean;
};

const FrontTableComp = <T extends { id?: string }>(
  { id, initialSorting, columns, tableData, isLoading = false, className }: FrontTableViewProps<T>,
  ref: Ref<FrontTableAPI<T>>,
) => {
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const table = useReactTable({
    columns: columns,
    data: tableData || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableSortingRemoval: false,
    sortDescFirst: false,
    state: {
      sorting,
    },
    defaultColumn: {
      minSize: 50,
      size: 600,
      sortingFn: 'text',
    },
    getRowId: (row) => row.id!,
  });

  useImperativeHandle(ref, () => ({
    tableApi: table,
  }));

  return <TableBase<T> id={id} table={table} isLoading={isLoading} className={className} />;
};

export const FrontTable = forwardRef(FrontTableComp) as <T extends { id?: string }>(
  props: FrontTableViewProps<T> & { ref?: ForwardedRef<FrontTableAPI<T>> },
) => ReactElement;
