import { useDeleteSignal } from '@/api/entities/signals';
import { SignalResponse } from '@/api/generated/Api';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@components/DropdownMenu';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { Trash2 } from 'lucide-react';
import { FC, useEffect, useRef } from 'react';

type SignalActionsProps = {
  signal: SignalResponse;
};

export const SignalActions: FC<SignalActionsProps> = ({ signal }) => {
  const abortController = useRef(new AbortController());

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  const { deleteSignal } = useDeleteSignal({
    signal: abortController.current.signal,
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <SvgIcon name="PaginationGap" className="w-7 rotate-90 p-[5px]" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem className="cursor-pointer gap-2" onClick={() => deleteSignal(signal.id)}>
          <Trash2 className="size-5" />
          <Typography text={'deleteSignal'} variant="sm/regular" className="text-grey-900 first-letter:capitalize" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
