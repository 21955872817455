import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

type InfoCardFooterProps = { className?: string } & PropsWithChildren;

export function InfoCardFooter({ className, children }: InfoCardFooterProps) {
  return (
    <footer className="flex">
      <div className={cn('flex', className)}>{children}</div>
    </footer>
  );
}
