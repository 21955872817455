import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { PaymentCard } from '@global/components/RadioCards/PaymentCard/PaymentCard';
import { TileLayout } from '@global/components/TileLayout';
import { ChangeEvent, FC, useState } from 'react';
import { TEST_PAYMENT_METHODS } from './constants';

export const PaymentMethodCard: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>('1');

  const handleClick = (id: string, _: ChangeEvent<HTMLInputElement>) =>
    setSelectedPlan((prev) => (prev === id ? null : id));

  return (
    <TileLayout className="gap-4py-6 pe-[22px]">
      <header className="flex justify-between">
        <div className="flex flex-col">
          <Typography text={'paymentMethod'} variant="lg/bold" className="capitalize text-grey-900" />
          <Typography
            text={'paymentMethodDescription'}
            variant="sm/regular"
            className="text-grey-900 first-letter:capitalize"
          />
        </div>
        <Button text={'add'} className="w-[114px]" />
      </header>
      <ul className="flex flex-col items-center gap-2">
        <Loadable
          isLoading={false}
          spinnerProps={{
            size: 'base',
          }}
        >
          {TEST_PAYMENT_METHODS.map((cardInfo) => (
            <li key={cardInfo.id} className="flex w-full justify-between pe-10">
              <PaymentCard checked={selectedPlan === cardInfo.id} onChange={handleClick} cardInfo={cardInfo} />
              <button className="justify-self-end">
                <Typography text={'edit'} variant="xs/bold" className="capitalize leading-[17px] text-primary-600" />
              </button>
            </li>
          ))}
        </Loadable>
      </ul>
    </TileLayout>
  );
};
