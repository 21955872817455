import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import { ComponentProps, FC } from 'react';
import { NavLink } from 'react-router-dom';

type InfoCardTitleProps = {
  title: string;
  link?: string;
  className?: string;
};

export const InfoCardTitle: FC<InfoCardTitleProps> = ({ title, link, className }) => {
  const props = {
    arbitrary: true,
    text: title,
    tag: 'h3',
    className: 'truncate text-[22px] font-bold leading-[26px] text-grey first-letter:capitalize',
  } satisfies ComponentProps<typeof Typography>;

  return link ? (
    <NavLink to={link} className="group">
      <Typography
        {...props}
        className={cn(
          props.className,
          'group-hover:text-primary-500 motion-safe:transition-colors motion-safe:duration-200 motion-reduce:transition-none',
        )}
      />
    </NavLink>
  ) : (
    <Typography {...props} />
  );
};
