import { cn } from '@/lib/utils';
import { LocalizedText, Typography } from '@components/Typography';
import { ComponentProps, ComponentPropsWithoutRef, FC, useId } from 'react';
import { BaseCheckbox } from './BaseCheckbox';

type CheckboxProps = {
  labelProps?: Omit<ComponentPropsWithoutRef<'label'>, 'htmlFor' | 'children'>;
  label: LocalizedText;
} & Omit<ComponentProps<typeof BaseCheckbox>, 'id'>;

export const Checkbox: FC<CheckboxProps> = ({ labelProps, label, ...checkboxProps }) => {
  const id = useId();

  return (
    <label
      {...labelProps}
      htmlFor={id}
      className={cn('group/radio flex w-fit cursor-pointer items-center gap-3', labelProps?.className)}
    >
      <BaseCheckbox id={id} {...checkboxProps} />
      <Typography {...label} variant="xs/semibold" className="capitalize text-grey-700" />
    </label>
  );
};
