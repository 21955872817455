import { useTeamMetrics } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { TeamMetric } from '../parts';
import { EducationCardModalContent } from './parts';

export const EducationMetric: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { data } = useTeamMetrics({
    companyId: id as string,
    signal,
  });

  return (
    <TeamMetric icon="Education" modalContent={<EducationCardModalContent />}>
      <TeamMetric.Title text="tierN" interpolationsParams={{ count: 1 }} />
      <TeamMetric.Subtitle
        subtitle={{
          text: 'education',
        }}
      />
    </TeamMetric>
  );
};
