import { z } from 'zod';
import { TFileType } from './parts/ImageInput/types';

export const updateUserValidation = (allowedFileTypes?: TFileType[]) =>
  z.object({
    firstName: z.string(),
    lastName: z.string(),
    company: z.string(),
  });

export type UpdateUserFormSchema = z.infer<ReturnType<typeof updateUserValidation>>;
