import { useContext } from 'react';
import { SignalsContext } from './context';

export const useSignalsPageValues = () => {
  const context = useContext(SignalsContext);

  if (!context) {
    throw new Error('useSignalsPageValues must be used within a SignalsPageProvider');
  }

  return context;
};
