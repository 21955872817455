import { cn } from '@/lib/utils';
import { FC, PropsWithChildren } from 'react';
import { AvatarFallback, AvatarImage, BaseAvatar } from './parts/BaseAvatar';

type AvatarProps = {
  src: string | undefined | null;
  file?: File | null;
  className?: string;
  alt?: string;
} & PropsWithChildren;

const PLACEHOLDER = '/profileplaceholder.png';

export const Avatar: FC<AvatarProps> = ({ file, src, className, alt, children }) => {
  const imageUrl = file ? URL.createObjectURL(file) : src;

  return (
    <BaseAvatar className={cn('size-10', className)}>
      <AvatarImage src={!!imageUrl ? imageUrl : PLACEHOLDER} />
      <AvatarFallback>
        <img src={PLACEHOLDER} alt={alt ?? 'Avatar'} />
      </AvatarFallback>
      {children}
    </BaseAvatar>
  );
};
