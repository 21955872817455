import { cn } from '@/lib/utils';
import { TableElement } from '@components/Tables/types';
import type { Row } from '@tanstack/react-table';
import { makeAccessible } from '@utils';
import { PropsWithChildren } from 'react';

type TableRowProps<T extends TableElement> = {
  index: number;
  enableRowSelection?: boolean;
  row: Row<T>;
  className?: string;
  style?: React.CSSProperties;
};

export const TableRow = <T extends TableElement>({
  index,
  enableRowSelection,
  row,
  children,
  className,
  style,
}: PropsWithChildren<TableRowProps<T>>) => (
  <tr
    data-index={index}
    aria-selected={row.getIsSelected()}
    className={cn(
      'text-grey-900',
      enableRowSelection
        ? 'aria-selected:bg-grey-500 cursor-pointer hover:aria-[selected=false]:bg-grey-300 motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none'
        : '',
      className,
    )}
    style={style}
    {...(enableRowSelection ? makeAccessible(() => row.toggleSelected()) : {})}
  >
    {children}
  </tr>
);
