const PAGINATION_LIMITS = [25, 50, 100, 200] as const;

export const PAGINATION_LIMIT_OPTIONS = PAGINATION_LIMITS.map((limit) => ({
  value: limit,
  label: limit,
}));

export const MIN_OFFSET = 0;

export const MIN_LIMIT = Math.min(...PAGINATION_LIMITS);
export const MAX_LIMIT = Math.max(...PAGINATION_LIMITS);
export const DEFAULT_LIMIT = 50;
