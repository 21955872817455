import { isAuthenticated } from '@global/authentication/service';
import { GlobalLoadingFallback } from '@global/components/Layout/GlobalLoadingFallback';
import { useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getRouter } from './router/router';

const App = () => {
  const routes = useMemo(() => getRouter(isAuthenticated), [isAuthenticated]);

  const generateRouter = createBrowserRouter(routes);

  return <RouterProvider fallbackElement={<GlobalLoadingFallback />} router={generateRouter} />;
};

export default App;
