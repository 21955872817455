import { Option } from '@global/types';
import { SetURLFilterArgs } from './types';

export const setURLStringFilter = ({ field, searchParams, value }: SetURLFilterArgs<string>) =>
  searchParams.set(field, value);

export const setURLNumberFilter = ({ field, searchParams, value }: SetURLFilterArgs<number>) =>
  searchParams.set(field, value.toString());

export const setURLBooleanFilter = ({ field, searchParams, value }: SetURLFilterArgs<boolean>) =>
  searchParams.set(field, value.toString());

export const setURLSelectFilter = <T extends string>({ field, searchParams, value }: SetURLFilterArgs<Option<T>>) =>
  searchParams.set(field, value.value);

export const setURLMultiSelectFilter = <T extends string[]>({ field, searchParams, value }: SetURLFilterArgs<T>) =>
  searchParams.set(field, value.join(','));
