import { useUpdateUser } from '@/api/entities/accounts/useUpdateUser';
import { UserResponse, useUserInfo } from '@/api/entities/accounts/useUserInfo';
import { useProfileImage } from '@/api/entities/documents/useProfileImage';
import { useUploadProfileImage } from '@/api/entities/documents/useUploadProfileImage';
import { QueryKeys } from '@/api/entities/queryKeys';
import { Avatar } from '@components/Avatar';
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { FormField } from '@global/components/FormField';
import { Loadable } from '@global/components/Loadable';
import { TileLayout } from '@global/components/TileLayout';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UpdateUserFormSchema, updateUserValidation } from '../../validator';
import { ImageInput } from '../ImageInput';
import { ALLOWED_IMAGE_TYPES } from '../ImageInput/constants';

export const PersonalInformation: FC = () => {
  const abortController = useRef(new AbortController());
  const queryClient = useQueryClient();

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<UpdateUserFormSchema>({
    resolver: zodResolver(updateUserValidation(ALLOWED_IMAGE_TYPES)),
    mode: 'onChange',
  });

  const { userInfo, isUserInfoLoading } = useUserInfo({
    signal: abortController.current.signal,
  });

  const { profileImage } = useProfileImage({ signal: abortController.current.signal });

  useEffect(() => {
    if (userInfo) {
      reset(userInfo);
    }
  }, [userInfo]);

  const { isUserUpdating, updateUser } = useUpdateUser({
    signal: abortController.current.signal,
    onSuccess: (data) => {
      queryClient.setQueryData<Partial<UserResponse>>([QueryKeys.UserInfo], (oldData) => ({
        ...oldData,
        firstName: data.firstName ?? oldData?.firstName,
        lastName: data.lastName ?? oldData?.lastName,
        company: data.company ?? oldData?.company,
      }));
    },
  });
  const { isProfileImageUploading, uploadImage } = useUploadProfileImage({
    signal: abortController.current.signal,
    onSuccess: (data) => queryClient.setQueryData<File | null>([QueryKeys.ProfileImage], () => data),
  });

  const handleImageChange = (file: File | null) => uploadImage(file);

  const onSubmit: SubmitHandler<UpdateUserFormSchema> = (data) => {
    updateUser(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TileLayout className="min-h-96 justify-center pb-[31px] pt-6">
        <Loadable isLoading={isUserInfoLoading}>
          <div className="flex w-[232px] flex-col gap-4">
            <Typography
              tag="h3"
              text="personalInformation"
              variant="sm/bold"
              className="capitalize leading-[25px] text-grey-900"
            />
            <div className="relative">
              <Button
                type="button"
                aria-hidden={!profileImage && !userInfo?.profileImageUrl}
                size="icon"
                onClick={() => handleImageChange(null)}
                className="absolute left-16 z-10 aria-hidden:hidden"
              >
                <SvgIcon name="Close" className="size-2" />
              </Button>
              <Avatar src={userInfo?.profileImageUrl} file={profileImage} className="size-[90px]" alt="avatar" />
              <ImageInput onChange={handleImageChange} className="left-[62px] top-[62px]" />
            </div>
            <FormField title="First Name">
              <Input {...register('firstName')} />
            </FormField>
            <FormField title="Last Name">
              <Input {...register('lastName')} />
            </FormField>
            <FormField title="Company">
              <Input {...register('company')} />
            </FormField>
            <FormField title="Email">
              <Input disabled value={localStorage.getItem('email') ?? ''} />
            </FormField>
            <Button
              text="save changes"
              className="w-[168px]"
              type="submit"
              disabled={!isDirty}
              isLoading={isUserUpdating || isProfileImageUploading}
            />
          </div>
        </Loadable>
      </TileLayout>
    </form>
  );
};
