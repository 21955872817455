import { cva } from 'class-variance-authority';

export const styles = cva('animate-spin rounded-full bg-gradient-to-r', {
  variants: {
    intent: {
      accent: ['from-primary-200 to-primary-700'],
      secondary: ['from-black from-40% to-primary-200'],
    },
    size: {
      xs: 'p-0.5 size-4',
      sm: 'p-1 size-8',
      base: 'p-1.5 size-10',
      lg: 'p-1.5 size-[52px]',
      xl: 'p-1.5 size-16',
    },
  },
  compoundVariants: [
    {
      intent: 'accent',
      size: 'base',
    },
  ],
  defaultVariants: {
    intent: 'accent',
    size: 'xl',
  },
});
