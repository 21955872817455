import { Typography } from '@components/Typography';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';

export const ActivePlanCard: FC = () => {
  return (
    <TileLayout className="py-6 pe-[22px]">
      <Typography text={'activePlan'} variant="lg/bold" className="capitalize text-grey-900" />
    </TileLayout>
  );
};
