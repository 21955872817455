import { useEffect, useState } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { httpDeleteUserList } from '../api/UserListsAPI';
import { IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import ConfirmPopup from './ConfirmPopup';
import { formatDateToDDMMYYYY } from '../lib/utils';

const CardList = ({ list, handleDeleteList }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {}, []);

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirm(true);
  };

  const confirmDelete = () => {
    httpDeleteUserList(list.id)
      .then((response) => {
        handleDeleteList(list.id);
      })
      .catch((error) => {});
    setShowConfirm(false);
  };

  return (
    <>
      {/* Confirmation Popup */}
      <ConfirmPopup
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        confirmText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this list?</p>
      </ConfirmPopup>

      <NavLink to={`/my-lists/${list.id}`} key={list.id}>
        <div className="bg-base-200 shadow-md rounded-md p-4 flex flex-col cursor-pointer hover:scale-105 transition-transform text-base-content relative group">
          <button
            onClick={handleDelete}
            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 p-2 rounded-full hover:bg-base-100 z-10"
          >
            <MdDeleteOutline className="text-error" size={21} />
          </button>
          <h3 className="text-[1.425rem] font-semibold">{list.name}</h3>
          <div className="flex mt-4 justify-left items-center mt-2 w-full gap-10">
            <div>
              <div className="font-semibold opacity-75">Number of Companies</div>
              <div>{list.companiesCount || '-'}</div>
            </div>
            <div>
              <div className="font-semibold opacity-75">Last Updated</div>
              <div className="font-thin">{list.updatedAt ? formatDateToDDMMYYYY(list.updatedAt) : '-'}</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="font-semibold opacity-75">Notes</div>
            <div>{list.notes || '-'}</div>
          </div>
        </div>
      </NavLink>
    </>
  );
};

export default CardList;
