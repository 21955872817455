import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { FC } from 'react';

type PageButtonProps = {
  page: number;
  setPage: (page: number) => void;
  offset: number;
};

export const PageButton: FC<PageButtonProps> = ({ offset, page, setPage }) => {
  const isCurrentPage = offset + 1 === page;

  return (
    <Button
      aria-current={isCurrentPage}
      onClick={() => setPage(page - 1)}
      intent={isCurrentPage ? 'accent' : 'shadow'}
      size="small"
      className="group duration-300 aria-[current=true]:pointer-events-none aria-[current=true]:cursor-not-allowed hover:bg-primary-600/30 motion-safe:transition-colors"
    >
      <Typography
        arbitrary
        text={page.toString()}
        variant="xs/regular"
        className="leading-3 text-grey-900 group-aria-[current=true]:text-grey-300"
      />
    </Button>
  );
};
