import { cn } from '@/lib/utils';
import { Button } from '@components/Button';
import { SvgIcon } from '@components/SvgIcon';
import { ChangeEvent, FC, useRef } from 'react';
import { ALLOWED_IMAGE_TYPES } from './constants';

type ImageInputProps = {
  onChange: (files: File) => void;
  className?: string;
};

export const ImageInput: FC<ImageInputProps> = ({ onChange, className }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onButtonClickHandler = () => inputRef.current?.click();

  const onChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange(event.target.files[0]);
      event.target.value = '';
    }
  };

  return (
    <Button
      type="button"
      size="icon"
      className={cn('absolute left-16 z-10 p-[7px]', className)}
      onClick={onButtonClickHandler}
    >
      <SvgIcon name="UploadImage" className="size-[14px]" />
      <input
        ref={inputRef}
        type="file"
        hidden
        multiple={false}
        onChange={onChangeEventHandler}
        accept={ALLOWED_IMAGE_TYPES ? ALLOWED_IMAGE_TYPES.flatMap((x) => x.mimeTypes).join(',') : undefined}
      />
    </Button>
  );
};
