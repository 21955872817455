import { api } from '@/api/apiV2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseUpdateUserArgs = {
  signal?: AbortSignal;
  onSuccess?: (id: string) => void;
};

export const useDeleteSignal = ({ signal, onSuccess }: UseUpdateUserArgs) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationKey: ['update-user'],
    mutationFn: async (id: string) => {
      await api.signal.deleteSignalById(id, { signal });

      queryClient.resetQueries({
        queryKey: [QueryKeys.Signals],
      });

      onSuccess?.(id);
    },
  });

  return {
    deleteSignal: mutate,
    isSignalDeleting: isPending,
  };
};
