import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

type InfoCardContentProps = { className?: string } & PropsWithChildren;

export function InfoCardContent({ className, children }: InfoCardContentProps) {
  return (
    <div className={cn('flex h-full flex-col justify-between gap-[18px] bg-grey-100 px-3 py-2.5', className)}>
      {children}
    </div>
  );
}
