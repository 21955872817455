import { cn } from '@/lib/utils';
import { ComponentPropsWithoutRef, FC } from 'react';

type PrevNextButtonWrapperProps = ComponentPropsWithoutRef<'button'>;

export const PrevNextButton: FC<PrevNextButtonWrapperProps> = ({ className, children, ...rest }) => (
  <button
    {...rest}
    className={cn(
      'flex items-center gap-2 rounded-lg px-3 py-2 duration-300 hover:bg-primary-600/30 disabled:pointer-events-none disabled:opacity-50 motion-safe:transition-colors',
      className,
    )}
  >
    {children}
  </button>
);
