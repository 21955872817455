import { PeopleEntity } from '@/api/entities/teams/types';
import { InfoCard } from '@global/components/InfoCard';
import { FC } from 'react';

type TeamMemberCardProps = {
  data: PeopleEntity;
};

export const TeamMemberCard: FC<TeamMemberCardProps> = ({ data }) => {
  return (
    <InfoCard>
      <InfoCard.Body>
        <InfoCard.Header
          image={data.profileAvatar}
          title={data.fullName}
          subtitle={data.headline}
          className="justify-center"
        />
        <InfoCard.Description>
          <InfoCard.DescriptionItem text="joinedAt" interpolationsParams={{ year: data.joinedAt ?? '-' }} />
          <InfoCard.DescriptionItem text="locationIs" interpolationsParams={{ location: data.addressWithCountry }} />
          <InfoCard.DescriptionItem text="ageEst" interpolationsParams={{ value: data.age ?? '-' }} />
        </InfoCard.Description>
        <InfoCard.Content>
          <InfoCard.ContentItem
            title={{
              text: 'experience_other',
              interpolationsParams: { punctuation: ':' },
            }}
          >
            {data.experiences.slice(0, 3).map((experience) => (
              <InfoCard.ContentItemContent
                key={`${data.id} - ${experience.subtitle} - ${experience.title} - ${experience.caption}`}
                text={`${experience.subtitle ? `${experience.subtitle} - ` : ''}${experience.title}${experience.caption ? ` (${experience.caption})` : ''}`}
                className="line-clamp-1"
              />
            ))}
          </InfoCard.ContentItem>
          <InfoCard.ContentItem
            title={{
              text: 'workedIn',
              interpolationsParams: { punctuation: ':' },
            }}
          >
            {data.educations.length
              ? data.educations
                  .slice(0, 2)
                  .map((education) => (
                    <InfoCard.ContentItemContent
                      key={`${data.id} - ${education.subtitle} - ${education.title} - ${education.caption}`}
                      text={`${education.subtitle ? `${education.subtitle} - ` : ''}${education.title}${education.caption ? ` (${education.caption})` : ''}`}
                      className="line-clamp-1"
                    />
                  ))
              : '-'}
          </InfoCard.ContentItem>
        </InfoCard.Content>
      </InfoCard.Body>
      <InfoCard.Footer>
        <InfoCard.FooterIcon icon="LinkedIn" src={`https://www.linkedin.com/in/${data.publicIdentifier}`} />
        <InfoCard.FooterIcon icon="Globe" />
        <InfoCard.FooterIcon icon="Mail" />
      </InfoCard.Footer>
    </InfoCard>
  );
};
