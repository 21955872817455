import { useCompany } from '@/api/entities/companies';
import { useTeamMembers } from '@/api/entities/teams';
import { PeopleEntity } from '@/api/entities/teams/types';
import { useAbortController } from '@/hooks';
import { FrontTable } from '@components/Tables';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EDUCATIONS_TABLE_COLUMNS } from './columns';

export const EducationTable: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { company } = useCompany({
    companyId: id as string,
    signal,
  });

  const { teamMembers } = useTeamMembers({
    companyId: id as string,
    companyName: company?.companyName,
    signal,
  });

  const filteredTeamMembers = useMemo(() => teamMembers?.filter((tm) => tm.educations.length > 0), [teamMembers]);

  return (
    <FrontTable<PeopleEntity>
      id="educations-table"
      columns={EDUCATIONS_TABLE_COLUMNS}
      tableData={filteredTeamMembers ?? []}
      className="max-h-[300px]"
      initialSorting={[
        {
          id: 'fullName',
          desc: false,
        },
      ]}
    />
  );
};
