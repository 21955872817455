import { useTeamMetrics } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const TeamNationalities: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { data, isFetching } = useTeamMetrics({
    companyId: id as string,
    signal,
  });

  return (
    <TileLayout className="min-w-[270px] gap-[1px] py-3 pe-3">
      <Typography text={'nationality'} variant="sm/bold" className="capitalize leading-[22px] text-grey-900" />
      <Loadable isLoading={isFetching} spinnerProps={{ size: 'lg' }}>
        <ul className="flex flex-col gap-[1px] overflow-y-auto pe-1">
          {data?.nationalities?.map((nationality) => (
            <li
              key={nationality.name}
              className="flex justify-between gap-[1px] *:leading-[22px] *:text-grey-900 *:first-letter:capitalize"
            >
              <Typography arbitrary text={nationality.name ? nationality.name : '-'} variant="sm/regular" />
              <Typography arbitrary text={nationality.count?.toString() ?? '0'} variant="sm/regular" />
            </li>
          ))}
        </ul>
      </Loadable>
    </TileLayout>
  );
};
