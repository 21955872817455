import React, { useState } from 'react';
import ConfirmPopup from './ConfirmPopup';
import { MdEdit, MdDelete } from 'react-icons/md';

function NoteCard({ note, selectedId, setSelectedId, resetNotes, handleChangeNote, handleDeleteNote, handleSaveNote }) {
  const [showConfirm, setShowConfirm] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const isLongContent = note.content.length > 100;
  const displayContent = isLongContent && !isExpanded ? note.content.slice(0, 100) + '...' : note.content;

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirm(true);
  };

  const confirmDelete = () => {
    handleDeleteNote(note.id);
    setShowConfirm(false);
  };

  if (selectedId !== note.id) {
    return (
      <div className="flex flex-col gap-2 p-2 bg-base-100 rounded-md shadow-md">
        <ConfirmPopup
          isOpen={showConfirm}
          onClose={() => setShowConfirm(false)}
          onConfirm={confirmDelete}
          title="Confirm Deletion"
          confirmText="Delete"
          cancelText="Cancel"
        >
          <p>Are you sure you want to delete this note?</p>
        </ConfirmPopup>
        <div className="flex justify-between items-center">
          <h2 className="text-md font-semibold">{note.title}</h2>
          {selectedId == null && (
            <div className="flex justify-between items-center gap-1">
              <button className="btn btn-primary btn-circle btn-sm btn-ghost" onClick={() => setSelectedId(note.id)}>
                <MdEdit />
              </button>
              <button className="btn btn-primary btn-circle btn-sm btn-ghost" onClick={handleDelete}>
                <MdDelete />
              </button>
            </div>
          )}
        </div>
        <div className="text-base font-thin">
          {displayContent}
          {isLongContent && (
            <button onClick={() => setIsExpanded(!isExpanded)} className="btn btn-link btn-sm px-0">
              {isExpanded ? 'Show less' : 'Read more'}
            </button>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-2 p-2 bg-base-100 rounded-md shadow-md pt-3">
        <div className="flex flex-col justify-left items-left gap-2">
          <label className="font-bold">Title:</label>
          <input
            className="input font-thin bg-base-100 input-bordered "
            placeholder="Insert title..."
            name="title"
            value={note.title}
            onChange={(e) => handleChangeNote(e, note.id)}
          />
        </div>
        <label className="font-bold mt-5">Content:</label>

        <textarea
          className="text-base font-thin textarea rounded-md border-1 border-neutral"
          placeholder="Insert content..."
          name="content"
          value={note.content}
          onChange={(e) => handleChangeNote(e, note.id)}
          rows={6}
        />
        <div className="flex justify-end items-center gap-4">
          <button className="btn btn-neutral btn-sm text-base-content" onClick={resetNotes}>
            Cancel
          </button>
          <button
            className="btn btn-sm bg-custom-gradient text-base-200 btn shadow-md border-none"
            onClick={handleSaveNote}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default NoteCard;
