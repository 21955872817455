import { Typography } from '@components/Typography';
import { NegativeLayout } from '@global/components/NegativeLayout';
import { FC } from 'react';

type NoTeamDataCardProps = {};

export const NoTeamDataCard: FC<NoTeamDataCardProps> = ({}) => {
  return (
    <NegativeLayout className='h-[400px]'>
      <Typography text={'noTeamPrompt'} variant="lg/bold" className="text-grey-900 first-letter:capitalize" />
    </NegativeLayout>
  );
};
