import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { TableElement, TableState } from './types';

export enum TableStateActions {
  UPDATE_SELECTED_ROWS = 'UPDATE_SELECTED_ROWS',
  SET_SORTING = 'SET_SORTING',
  UPDATE_AFTER_FETCH = 'UPDATE_AFTER_FETCH',
  SET_PAGE_INDEX = 'SET_PAGE_INDEX',
  SET_PAGE_SIZE = 'SET_PAGE_SIZE',
}

export type Action<T extends TableElement> =
  | { type: TableStateActions.UPDATE_SELECTED_ROWS; payload: ColumnDef<T>[] }
  | { type: TableStateActions.SET_SORTING; payload: SortingState }
  | {
      type: TableStateActions.UPDATE_AFTER_FETCH;
      payload: T[];
      totalRecords: number;
    }
  | { type: TableStateActions.SET_PAGE_INDEX; payload: number }
  | { type: TableStateActions.SET_PAGE_SIZE; payload: number };

export const baseTableReducer = <T extends TableElement>(state: TableState<T>, action: Action<T>): TableState<T> => {
  switch (action.type) {
    case TableStateActions.UPDATE_SELECTED_ROWS:
      return { ...state, columns: action.payload };
    case TableStateActions.SET_SORTING:
      return { ...state, sorting: action.payload };
    case TableStateActions.SET_PAGE_INDEX:
      return { ...state, offset: action.payload };
    case TableStateActions.SET_PAGE_SIZE:
      return { ...state, limit: action.payload };
    case TableStateActions.UPDATE_AFTER_FETCH:
      return {
        ...state,
        tableData: action.payload,
        totalRecords: action.totalRecords,
      };
    default:
      return state;
  }
};
