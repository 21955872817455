import { cn } from '@/lib/utils';
import { Spinner } from '@components/Spinner';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from './Button.styles';
import type { ButtonProps } from './types';

export const Button: FC<ButtonProps> = ({
  children,
  onClick = () => null,
  text,
  className,
  intent = 'accent',
  isLoading = false,
  size = 'medium',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      disabled={props.disabled || isLoading}
      onClick={onClick}
      className={cn(styles({ intent, isLoading, size }), className)}
    >
      {isLoading ? <Spinner intent='secondary' size='xs'/> : text ? t(text) : children}
    </button>
  );
};
