import { api } from '@/api/apiV2';
import { SignalFilters } from '@/api/entities/signals/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseSignalsArgs = {
  filters: SignalFilters;
  signal: AbortSignal;
};

export const useSignals = ({ filters, signal }: UseSignalsArgs) => {
  const adjustedFilters: Parameters<typeof api.signal.getSignals>[0] = {
    Type: filters.type,
    Sorting: filters.sorting,
    ShowDuplicates: filters.dontShowDuplicates === null ? false : !filters.dontShowDuplicates,
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKeys.Signals, adjustedFilters],
    queryFn: async ({ pageParam = 0 }) => {
      const { dontShowDuplicates, ...rest } = filters;

      const response = await api.signal.getSignals(
        {
          ...adjustedFilters,
          Offset: pageParam,
        },
        {
          signal: signal,
        },
      );

      return response.data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPage.hasNextPage) {
        return lastPageParam + 1;
      }

      return undefined;
    },
    getPreviousPageParam: (_, _allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }

      return firstPageParam - 1;
    },
  });

  return {
    ...query,
    isEmpty: query.data?.pages.flatMap((page) => page.signalsGroups).length === 0,
  };
};
