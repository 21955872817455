import { customAxios } from '@/api/customAxios';
import { useMutation } from '@tanstack/react-query';

type UseUploadProfileImageArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: File | null) => void;
};

export const useUploadProfileImage = ({ signal, onSuccess }: UseUploadProfileImageArgs) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['profile-image'],
    mutationFn: async (data: File | null) => {
      var formData = new FormData();
      if (data) {
        formData.append('file', data);
      }

      await customAxios.put('/api/documents/profile-image', formData);

      onSuccess?.(data);
    },
  });

  return {
    uploadImage: mutate,
    isProfileImageUploading: isPending,
  };
};
