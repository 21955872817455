import { LimitSelector, MIN_OFFSET, PageButtons, PrevNextButton } from '@components/Pagination';
import { ArrowLeft, ArrowRight } from '@components/SvgIcon/Icons';
import { Action, TableStateActions } from '@components/Tables/reducer';
import { TableElement } from '@components/Tables/types';
import { Typography } from '@components/Typography';
import type { PaginationControlState } from './types';

type TablePaginationProps<T extends TableElement> = {
  paginationState: PaginationControlState;
  dispatch: (value: Action<T>) => void;
};

export const TablePagination = <T extends TableElement>({ paginationState, dispatch }: TablePaginationProps<T>) => {
  const { limit, offset, totalRecords } = paginationState;

  const actualOffset = Math.max(MIN_OFFSET, offset);

  const totalPages = Math.ceil(totalRecords / limit);
  const isFirstPage = actualOffset === MIN_OFFSET;
  const isLastPage = actualOffset + 1 >= totalPages;

  const setLimit = (newLimit: number) => {
    dispatch({ type: TableStateActions.SET_PAGE_SIZE, payload: newLimit });
    dispatch({ type: TableStateActions.SET_PAGE_INDEX, payload: 0 });
  };

  const setOffset = (newOffset: number) => {
    dispatch({ type: TableStateActions.SET_PAGE_INDEX, payload: newOffset });
  };

  return (
    <footer className="flex items-center justify-between">
      <Typography arbitrary text={`${totalRecords} results`} variant="xs/medium" className="text-grey-900" />
      <nav className="flex items-center gap-2">
        <div className="flex items-center gap-2 px-3 py-2">
          <Typography
            arbitrary
            text="results per page"
            variant="xs/medium"
            className="text-grey-900 first-letter:capitalize"
          />
          <LimitSelector limit={limit} setLimit={setLimit} />
        </div>
        <PrevNextButton disabled={isFirstPage} onClick={() => setOffset(actualOffset - 1)}>
          <ArrowLeft disabled={isFirstPage} />
          <Typography text="previous" variant="xs/regular" className="capitalize text-grey-700" />
        </PrevNextButton>
        <PageButtons setPage={setOffset} totalPages={totalPages} offset={offset} />
        <PrevNextButton disabled={isLastPage} onClick={() => setOffset(actualOffset + 1)}>
          <Typography text="next" variant="xs/regular" className="capitalize text-grey-700" />
          <ArrowRight disabled={isLastPage} />
        </PrevNextButton>
      </nav>
    </footer>
  );
};
