import { useTeamMetrics } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { Typography } from '@components/Typography';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { TeamMetric } from '../parts';

export const AverageAgeMetric: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { data } = useTeamMetrics({
    companyId: id as string,
    signal,
  });

  return (
    <TeamMetric icon="Person">
      <TeamMetric.Title text="nYears" interpolationsParams={{ count: data?.averageAge ?? 0 }} />
      <TeamMetric.Subtitle
        subtitle={{
          text: 'averageAge',
        }}
      />
      <Typography
        text={'minMaxAge'}
        interpolationsParams={{ from: data?.minAge ?? 0, to: data?.maxAge ?? 0 }}
        variant="xs/light"
        className="leading-[22px] text-grey-900 first-letter:capitalize"
      />
    </TeamMetric>
  );
};
