import { useCompany } from '@/api/entities/companies';
import { useAbortController } from '@/hooks';
import { Avatar } from '@components/Avatar';
import { LinkSubTabs } from '@components/LinkTabs/LinkSubTabs';
import { TabItem } from '@components/Tabs';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CapTableTab } from './tabs/CapTableTab';
import { TeamBoardMembersTab } from './tabs/TeamBoardMembersTab';

const items: TabItem[] = [
  {
    title: 'teamAndBoardMembers',
    element: <TeamBoardMembersTab />,
  },
  {
    title: 'capTable',
    element: <CapTableTab />,
  },
];

export const Team: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { company, isFetching } = useCompany({
    companyId: id as string,
    signal,
  });

  return (
    <div className="flex w-full flex-col gap-[22px]">
      <header className="flex items-center gap-2">
        <Loadable isLoading={isFetching} spinnerProps={{ size: 'sm' }} className="justify-start ps-10">
          <Avatar src={company?.logoUrl} className="size-[30px]" />
          <Typography
            arbitrary
            text={company?.companyName ?? '-'}
            variant="lg/bold"
            className="leading-8 text-grey-900 first-letter:capitalize"
          />
        </Loadable>
      </header>
      <LinkSubTabs items={items} stepName="step" />
    </div>
  );
};
