import { AxiosError, AxiosRequestConfig } from 'axios';
import { getAccessToken, resetTokenAndReattemptRequest } from './customAxios';
import { Api, HttpClient } from './generated/Api';

const securityWorker: () => AxiosRequestConfig = () => {
  const token = getAccessToken();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const httpClient = new HttpClient({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  securityWorker: securityWorker,
  secure: true,
  timeout: 60000,
});

export const api = new Api(httpClient);

api.http.instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    switch (error.response?.status) {
      case 401:
        return resetTokenAndReattemptRequest(error);

      case 404:
        return error.response;

      case undefined:
        alert('Could Not Connect To Server'); // Using alert as a placeholder
        return Promise.reject(error);

      case 500:
        alert('Server Error'); // Using alert as a placeholder
        return Promise.reject(error);

      default: {
        alert('Something goes wrong');
        return Promise.reject(error);
      }
    }
  },
);
