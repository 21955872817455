import { SvgIcon } from '@components/SvgIcon';
import { FC } from 'react';
import { SIBLINGS } from './constants';
import { PageButton } from './parts/PageButton';

type PageButtonsProps = {
  totalPages: number;
  setPage: (page: number) => void;
  offset: number;
};

export const PageButtons: FC<PageButtonsProps> = ({ offset, setPage, totalPages }) => {
  const currentPage = offset + 1;

  const generateLinks = () => {
    let pageNumbers: (string | number)[] = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= 3) {
      for (let i = 1; i <= Math.min(3, totalPages); i++) {
        pageNumbers.push(i);
      }

      if (currentPage === 3 && totalPages > 3) {
        pageNumbers.push(4);
      }

      if (totalPages > 5) {
        pageNumbers.push('...');
      }

      if (totalPages > 4) {
        pageNumbers.push(totalPages - 1);
        pageNumbers.push(totalPages);
      }
    } else if (currentPage >= totalPages - 2) {
      pageNumbers.push(1);
      pageNumbers.push(2);
      if (totalPages > 5) {
        pageNumbers.push('...');
      }
      if (currentPage === totalPages - 2) {
        pageNumbers.push(currentPage - 1);
      }

      for (let i = Math.max(totalPages - 2, 1); i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (currentPage > SIBLINGS + 2) {
        pageNumbers.push('...');
      }
      for (let i = currentPage - SIBLINGS; i <= currentPage + SIBLINGS; i++) {
        pageNumbers.push(i);
      }
      if (currentPage < totalPages - SIBLINGS - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="flex gap-2">
      {generateLinks().map((page, index) => {
        const key = `page-${page}`;

        return typeof page === 'number' ? (
          <PageButton key={key} page={page} setPage={setPage} offset={offset} />
        ) : (
          <SvgIcon key={`${key}-${index}`} name="PaginationGap" className="self-end py-2" />
        );
      })}
    </div>
  );
};
