import { useSidebarActions } from '@/contexts/SidebarContext/useSidebarActions';
import { RoutePath } from '@/router/constants';
import { FC, useEffect, useState } from 'react';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { LuMessagesSquare } from 'react-icons/lu';
import { NavLink } from 'react-router-dom';

export const Header: FC = () => {
  const { toggleSidebar } = useSidebarActions();

  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <header className="sticky left-0 top-0 z-[1051] flex h-header w-full items-center justify-between border-b bg-base-100 px-4 ps-2">
      <div className="flex items-center justify-start">
        <HiOutlineBars3
          size="50"
          onClick={toggleSidebar}
          className="cursor-pointer pt-3 duration-500 hover:text-purple-500"
        />
        <NavLink to={RoutePath.HOME}>
          <img width="180px" className="pl-4" src="/logo.png" alt="logo" />
        </NavLink>
      </div>
      {/* <input type="checkbox" className="toggle theme-controller mr-10" onClick={toggleTheme} /> */}
      <button
        className="btn hidden items-center rounded-full border-none bg-gradient-to-r from-primary-200 to-primary-600 py-2 text-white lg:display md:flex"
        onClick={() => window.open('https://forms.gle/2qUL9Hq2dM54gKN9A', '_blank')}
      >
        <LuMessagesSquare size="15" />
        Give us feedback
      </button>
    </header>
  );
};
