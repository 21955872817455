import { SignalResponse } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { useSignalsPageActions } from '@/pages/Signals/context';
import { Button } from '@components/Button';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { FC, PropsWithChildren } from 'react';
import { SignalActions } from './parts/SignalActions/SignalActions';
import { SignalTypeTag } from './parts/SignalTypeTag/SignalTypeTag';

type SignalCardHeaderProps = {
  signal: SignalResponse;
  className?: string;
} & PropsWithChildren;

export const SignalCardHeader: FC<SignalCardHeaderProps> = ({ signal, className, children }) => {
  const { openAddToListPopup } = useSignalsPageActions();

  const handleAddToList = () => {
    if (signal.company.companyId) {
      openAddToListPopup(signal.company.companyId);
    }
  };

  return (
    <header className={cn('flex items-center justify-between', className)}>
      <div className="flex items-center gap-1.5">
        <SignalTypeTag type={signal.signalType} />
        {children}
      </div>
      <div className="flex items-center gap-0.5 py-0.5">
        {signal.company.companyId ? (
          <Button onClick={handleAddToList} intent={'accent'} className="flex items-center gap-1.5 px-2 py-1">
            <SvgIcon name="Plus" />
            <Typography text="add" variant="sm/regular" className="capitalize leading-4 text-base-50" />
          </Button>
        ) : null}
        {/* <SvgIcon name="PaginationGap" className="w-7 rotate-90 p-[5px]" /> */}
        <SignalActions signal={signal} />
      </div>
    </header>
  );
};
