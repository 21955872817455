import { useUserInfo } from '@/api/entities/accounts/useUserInfo';
import { useProfileImage } from '@/api/entities/documents/useProfileImage';
import { auth } from '@/lib/firebase';
import { Avatar } from '@components/Avatar';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { signOut } from 'firebase/auth';
import { FC, useEffect, useRef } from 'react';
import { IoIosLogOut } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

export const UserInfo: FC = () => {
  const abortController = useRef(new AbortController());

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  const { userInfo, isUserInfoLoading } = useUserInfo({ signal: abortController.current.signal });
  const { profileImage } = useProfileImage({ signal: abortController.current.signal });

  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('userId');
      localStorage.removeItem('email');
      await signOut(auth);

      window.location.href = '/login';
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div className="flex items-center justify-between gap-1 rounded-md border-gray-600 sidebar-closed:flex-col sidebar-closed:gap-1 motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none">
      <Loadable
        isLoading={isUserInfoLoading}
        spinnerProps={{
          size: 'sm',
        }}
      >
        <NavLink
          to="/profile"
          className="flex items-center gap-0 sidebar-expanded:gap-[9px] motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none"
        >
          <Avatar
            file={profileImage}
            src={userInfo?.profileImageUrl}
            className="size-8 sidebar-expanded:size-10 motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none"
            alt="profile"
          />
          <Typography
            arbitrary
            text={userInfo?.email ?? '-'}
            variant="sm/medium"
            className="invisible w-0 truncate font-primary opacity-0 sidebar-expanded:visible sidebar-expanded:w-[150px] sidebar-expanded:opacity-100 motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none dark:text-slate-600"
          />
        </NavLink>
      </Loadable>
      <button
        onClick={handleLogout}
        className="hover-opacity text-md cursor-pointer rounded-full p-2 font-primary motion-safe:transition-opacity motion-safe:duration-500 motion-reduce:transition-none"
      >
        <IoIosLogOut size="22" />
      </button>
    </div>
  );
};
