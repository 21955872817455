import { FC } from 'react';
import { ActivePlanCard } from './parts/ActivePlanCard';
import { PaymentHistory } from './parts/PaymentHistory';
import { PaymentMethodCard } from './parts/PaymentMethodCard';

export const Billing: FC = () => (
  <div className="flex h-full gap-[21px]">
    <div className="flex flex-col gap-[11px] w-full">
      <PaymentMethodCard />
      <ActivePlanCard />
    </div>
    <PaymentHistory />
  </div>
);
