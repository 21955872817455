import React from 'react';

const FundingInfo = ({ fundingInfo }) => {
  return (
    <div className=" flex  flex-col justify-between gap-4">
      {/* Title*/}
      <h2 className="text-lg font-semibold">Funding Insights</h2>
      <table className="table w-full">
        <thead className="font-semibold opacity-70 text-left">
          <tr>
            <th className="border-b"></th>
            <th className="border-b">Amount</th>
            <th className="border-b">Stage</th>
            <th className="border-b">Date</th>
          </tr>
        </thead>
        <tbody className="font-semibold text-left">
          {fundingInfo.lastRound && (
            <tr className="">
              <td className="text-left opacity-70 border-b ">Latest Funding Round</td>
              <td className="border-b"> {fundingInfo.lastRound.amountRaised}</td>
              <td className="border-b"> {fundingInfo.lastRound.type}</td>
              <td className="border-b"> {fundingInfo.lastRound.date}</td>
            </tr>
          )}
          <tr className="">
            {' '}
            <td className="text-left opacity-70 "></td>
          </tr>

          {fundingInfo.firstListedInvestor && (
            <tr className="">
              <td className="text-left opacity-70 border-b ">Key Investors</td>
              <td className="border-b"> {fundingInfo.firstListedInvestor.name}</td>
            </tr>
          )}
          {fundingInfo.totalRounds && (
            <tr className="">
              <td className="text-left opacity-70 border-b ">Total Rounds</td>
              <td className="border-b"> {fundingInfo.totalRounds}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FundingInfo;
