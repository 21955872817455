import { getAdditionalUserInfo, signInWithPopup } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { httpGoogleAuth, httpLogin } from '../api/AccountsAPI';
import { auth, googleProvider } from '../lib/firebase';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !password) {
      setError('All fields are required');
      return false;
    } else if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      window.location.href = '/home';
    }
  }, []);

  const signInWithGoogle = async () => {
    try {
      const user = await signInWithPopup(auth, googleProvider);

      if (user) {
        const profile = getAdditionalUserInfo(user).profile;

        const response = await httpGoogleAuth(
          user['_tokenResponse'].idToken,
          user['_tokenResponse'].refreshToken,
          profile['family_name'],
          profile['given_name'],
        );

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('refresh-token', response.data.refreshToken);
        localStorage.setItem('email', response.data.email);

        navigate('/home');
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleLogin = () => {
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    httpLogin(email, password)
      .then((response) => {
        setSuccess(true);
        setError(false);
        setEmail('');
        setPassword('');
        setLoading(false);

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('refresh-token', response.data.refreshToken);
        localStorage.setItem('email', response.data.email);

        window.location.href = '/home';
      })
      .catch((error) => {
        setError('Invalid email or password');
        setLoading(false);
      });
  };

  return (
    <div className="flex min-h-screen w-full flex-wrap items-stretch justify-center bg-white">
      <div className="flex h-[100%] w-[80%] w-full flex-col items-center justify-evenly bg-white py-10 lg:w-[50%]">
        <div className="flex flex-col items-start justify-items-start pb-10 lg:w-[80%]">
          <img className="w-[160px]" src="/logo.png" alt="logo" />
        </div>
        <form
          className="flex w-full flex-col items-center justify-center gap-6 bg-white px-10 pt-10 lg:px-0"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          {/* LOG IN */}
          <h1 className="w-full text-start text-2xl text-slate-700 lg:w-[80%] lg:text-4xl">LOG IN</h1>
          {/* FORM */}
          <div className="flex w-full flex-col items-center justify-center">
            <label htmlFor="email" className="w-full text-start lg:w-[80%]">
              Email*
            </label>
            <input
              className="mt-2 w-full rounded-md border border-slate-400 bg-white p-3 text-slate-900 lg:w-[80%]"
              id="email"
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <label htmlFor="password" className="w-full text-start lg:w-[80%]">
              Password*
            </label>
            <input
              className="mt-2 w-full rounded-md border border-slate-400 bg-white p-3 text-slate-900 lg:w-[80%]"
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* ERRORS */}
          {error && <p className="pl-3 text-left text-sm text-error lg:w-[80%]"> * {error}</p>}
          {success && (
            <p className="border-l border-green-500 pl-3 text-left text-sm text-green-600 lg:w-[80%]">
              Logged In Successfully
            </p>
          )}
          <button
            type="submit"
            className="w-full rounded-lg border-none bg-primary-600 p-2 text-lg font-semibold text-primary-content duration-200 hover:brightness-[1.1] lg:w-[80%]"
          >
            {!loading ? 'Sign In' : <ClipLoader color="white" size="25" />}
          </button>
          {/* RECOVER PASSWORD */}
          <NavLink
            to="/password-recovery"
            className="text-md w-[80%] cursor-pointer text-center text-slate-900 underline"
          >
            Forgot your password
          </NavLink>
          <div className="divider mx-auto my-0 w-[80%]">OR</div>
          <div
            onClick={signInWithGoogle}
            className="flex w-full cursor-pointer items-center justify-center rounded-md border border-slate-400 p-3 text-lg font-semibold text-black duration-200 hover:bg-gray-100 lg:w-[80%]"
          >
            <FaGoogle />
            <span className="pl-2">Log in with Google</span>
          </div>
          <div className="divider mx-auto my-0 w-[80%]"></div>
          <p className="text-md flex w-full items-center justify-center text-slate-700 lg:text-xl">
            Don&apos;t have an account yet?
          </p>
          <NavLink
            to="/signup"
            className="hover: w-full rounded-lg border-none bg-gray-700 p-2 text-center text-lg font-semibold text-primary-content duration-200 lg:w-[80%]"
          >
            Create an new Account
          </NavLink>
        </form>
      </div>
      {/* LOGO */}

      {/* Right side Image */}
      <div className="hidden w-full flex-grow lg:flex lg:w-[50%]">
        <img src="/logindesign.png" className="h-[100%] w-full object-cover" alt="login design" />
      </div>
    </div>
  );
}

export default Login;
