import { useEffect, useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { MdFileUpload } from 'react-icons/md';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { ClipLoader } from 'react-spinners';
import { httpGetUserLists } from '../api/UserListsAPI';
import CardList from '../components/CardList';
import CreateListPopup from '../components/CreateListPopup';

const MyLists = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = () => {
    setIsLoading(true);

    httpGetUserLists()
      .then((response) => {
        setLists(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [showSublists, setShowSublists] = useState({});

  const toggleSublists = (listID) => {
    setShowSublists((prev) => ({
      ...prev,
      [listID]: !prev[listID],
    }));
  };

  const handleDeleteList = (listId) => {
    setLists((currentLists) => currentLists.filter((list) => list.id !== listId));
  };

  return (
    <div className="flex w-full flex-col gap-7">
      {isModalOpen && <CreateListPopup handleModalToggle={handleModalToggle} fetchLists={fetchLists} />}
      <div className="text-3xl font-semibold">My Lists</div>
      <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
        <div className="w-full sm:w-1/2">
          <button
            onClick={() => setIsModalOpen(true)}
            className="btn flex w-full items-center text-base-content shadow-md transition-transform hover:scale-102 hover:border-none hover:bg-base-200"
          >
            <FiPlusCircle size="24" className="cursor-pointer duration-500" />
            <span>CREATE NEW LIST</span>
          </button>
        </div>
        <div className="w-full sm:w-1/2">
          <button
            disabled={true}
            className="btn flex w-full items-center shadow-md disabled:bg-base-200 disabled:text-neutral"
          >
            <MdFileUpload size="24" />
            <span>UPLOAD NEW LIST</span>
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center pt-20">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="1rem">
            {lists?.length > 0 ? (
              lists.map((list, index) => <CardList key={index} list={list} handleDeleteList={handleDeleteList} />)
            ) : (
              <div className="justify-left flex w-full items-center text-xl font-semibold text-base-content">
                0 lists found
              </div>
            )}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </div>
  );
};

export default MyLists;
