import { Typography } from '@components/Typography';
import { FC } from 'react';

type TechnicalFiguresMetricModalContentProps = {};

export const TechnicalFiguresMetricModalContent: FC<TechnicalFiguresMetricModalContentProps> = ({}) => {
  return (
    <div className="flex flex-col gap-6">
      <header className="contents">
        <Typography
          text={'technicalFigure_other'}
          variant="lg/bold"
          className="capitalize leading-[30px] text-grey-900"
        />
        <div className="flex items-end gap-2 *:capitalize *:text-grey-900">
          <Typography
            text={'nPercentage'}
            interpolationsParams={{ count: 22 }}
            variant="doubleXl/bold"
            className="leading-[22px]"
          />
        </div>
      </header>
    </div>
  );
};
