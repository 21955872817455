import { cva } from 'class-variance-authority';

export const styles = cva(
  'flex justify-center uppercase font-bold disabled:cursor-not-allowed motion-safe:transition-colors duration-300 text-white h-fit w-fit drop-shadow-md text-xs leading-[17px] disabled:opacity-50 p-1 focus:outline-none',
  {
    variants: {
      intent: {
        accent: ['shadow-lg bg-gradient-to-r from-gradient-from to-gradient-to'],
        shadow: [],
        empty: ['text-grey bg-white'],
        secondary: [
          'bg-gradient-to-r from-gradient-from text-transparent bg-clip-text to-gradient-to shadow-[0px_3px_12px_0px] shadow-primary-700/30',
        ],
      },
      size: {
        small: 'rounded-lg px-3 py-2',
        medium: 'rounded-[4px] px-[16px] py-1.5',
        large: 'rounded-lg px-4 py-1.5',
        icon: 'rounded-full',
      },
      isLoading: {
        true: 'overflow-hidden text-transparent',
      },
    },
    compoundVariants: [
      {
        intent: 'accent',
        size: 'medium',
        isLoading: false,
      },
    ],
    defaultVariants: {
      intent: 'accent',
      size: 'medium',
    },
  },
);
