import { cn } from '@/lib/utils';
import { ComponentPropsWithoutRef, FC } from 'react';

type TableCheckboxProps = Omit<ComponentPropsWithoutRef<'input'>, 'type'>;

export const TableCheckbox: FC<TableCheckboxProps> = ({ className, ...rest }) => (
  <input
    {...rest}
    type="checkbox"
    className={cn(
      'checkbox-primary checkbox h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500',
      className,
    )}
  />
);
