import { cn } from '@/lib/utils';
import { Spinner } from '@components/Spinner';
import type { ComponentProps, FC, ReactNode } from 'react';

type LoadableProps = {
  isLoading: boolean | undefined;
  children: ReactNode;
  className?: string;
  spinnerProps?: ComponentProps<typeof Spinner>;
};

export const Loadable: FC<LoadableProps> = ({ children, isLoading, className, spinnerProps }) => {
  if (isLoading)
    return (
      <div className={cn('flex grow items-center justify-center', className)}>
        <Spinner {...spinnerProps} />
      </div>
    );
  return <>{children}</>;
};
