import { FC } from 'react';

export type IconProps = {
  className?: string;
  pathClassName?: string;
};

export const SignalsIcon: FC<IconProps> = ({ className, pathClassName }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path className={pathClassName} d="M2 10v3" />
    <path className={pathClassName} d="M6 6v11" />
    <path className={pathClassName} d="M10 3v18" />
    <path className={pathClassName} d="M14 8v7" />
    <path className={pathClassName} d="M18 5v13" />
    <path className={pathClassName} d="M22 10v3" />
  </svg>
);

export const ArrowLeft: FC<IconProps & { disabled?: boolean }> = ({ disabled, className, pathClassName }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {disabled ? (
      <path
        className={pathClassName}
        d="M12.6666 8.00004H3.33325M3.33325 8.00004L7.99992 12.6667M3.33325 8.00004L7.99992 3.33337"
        stroke="#4D5E80"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ) : (
      <>
        <path
          className={pathClassName}
          d="M12.6666 8.00004H3.33325M3.33325 8.00004L7.99992 12.6667M3.33325 8.00004L7.99992 3.33337"
          stroke="url(#paint0_linear_1530_14649)"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1530_14649"
            x1="5.92285"
            y1="3.33338"
            x2="11.0494"
            y2="3.6724"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8177FF" />
            <stop offset="1" stopColor="#BC65FF" />
          </linearGradient>
        </defs>
      </>
    )}
  </svg>
);

export const ArrowRight: FC<IconProps & { disabled?: boolean }> = ({ disabled, className, pathClassName }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {disabled ? (
      <path
        className={pathClassName}
        d="M4.31648 8.0268L13.6498 8.00705M13.6498 8.00705L8.97327 3.35027M13.6498 8.00705L8.99301 12.6836"
        stroke="#4D5E80"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ) : (
      <>
        <path
          className={pathClassName}
          d="M3.33337 8.00004H12.6667M12.6667 8.00004L8.00004 3.33337M12.6667 8.00004L8.00004 12.6667"
          stroke="url(#paint0_linear_1530_14438)"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1530_14438"
            x1="5.92297"
            y1="3.33338"
            x2="11.0496"
            y2="3.6724"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8177FF" />
            <stop offset="1" stopColor="#BC65FF" />
          </linearGradient>
        </defs>
      </>
    )}
  </svg>
);
