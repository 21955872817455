import { MIN_OFFSET } from '@/components/Pagination';
import { useDefaultPagination } from '@/hooks/useDefaultPagination';
import { DEFAULT_LIMIT, MAX_LIMIT, MIN_LIMIT } from '@components/Pagination/constants';
import { useEffect, useState } from 'react';
import { FaChevronRight, FaTrash } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { httpDeleteCompaniesFromList, httpGetListCompanies, httpGetListInfo } from '../api/UserListsAPI';
import CompaniesTable from '../components/CompaniesTable';
import ConfirmPopup from '../components/ConfirmPopup';

const List = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { list_id } = useParams();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myArea = queryParams.get('from');

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useDefaultPagination();

  const offset = Math.max(MIN_OFFSET, +(searchParams.get('offset') ?? MIN_OFFSET));

  const initialLimit = searchParams.get('limit');
  const limit =
    initialLimit === null ? DEFAULT_LIMIT : Math.max(MIN_LIMIT, Math.min(+(initialLimit ?? MIN_LIMIT), MAX_LIMIT));

  useEffect(() => {
    httpGetListInfo(list_id)
      .then((response) => {
        setCompanyInfo(response.data);
      })
      .catch((error) => {});

    if (offset !== undefined && limit !== undefined) {
      search(offset * limit, limit);
    }
  }, []);

  const search = (offset, limit) => {
    setIsLoading(true);
    httpGetListCompanies(list_id, offset, limit)
      .then((response) => {
        setResponse(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setPage = (newOffset) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: newOffset,
    });

    search(newOffset * limit, limit);
  };

  const setPageSize = (limit) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: MIN_OFFSET,
      limit: limit,
    });

    search(MIN_OFFSET * limit, limit);
  };

  const confirmDelete = () => {
    httpDeleteCompaniesFromList(list_id, selectedIds)
      .then((response) => {
        search(offset, limit);
        setSelectedIds([]);
      })
      .catch((error) => {})
      .finally(() => {
        setShowConfirmDelete(false);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {/* Delete Popup */}
      <ConfirmPopup
        isOpen={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        confirmText="Delete"
        cancelText="Cancel"
      >
        <p className="text-center">
          Are you sure you want to delete <b>{selectedIds.length}</b> companies from this list?
        </p>
      </ConfirmPopup>
      <div className="flex w-full flex-col gap-7">
        <div className="z-[1050] flex items-center gap-3 text-3xl font-semibold">
          <button onClick={handleBack}>{myArea ? 'Lists & Searches' : 'My Lists'}</button>
          <FaChevronRight className="mt-[2px] text-2xl" />
          {companyInfo?.name}
        </div>
        <div className="relative flex w-full flex-col">
          {isLoading ? (
            <div className="flex items-center justify-center pt-20">
              <ClipLoader color="primary" size="40px" />
            </div>
          ) : response?.totalRecords > 0 ? (
            <CompaniesTable
              setSelectedIds={setSelectedIds}
              selectedIds={selectedIds}
              setPage={setPage}
              setPageSize={setPageSize}
              response={response}
              size="large"
              displaySimilarity={false}
            />
          ) : (
            <div className="card-elevation flex w-full flex-col items-center justify-start gap-5 bg-center p-40">
              <h2 className="text-3xl font-bold text-base-content lg:text-5xl">Ops...</h2>
              <p className="mb-4 text-lg">
                It seems like this list is empty. Click the button below to search companies to add.
              </p>
              <NavLink to="/home" className="btn btn-primary">
                Go to Search
              </NavLink>
            </div>
          )}

          {/* Manipulate Selected */}
          {selectedIds.length > 0 && (
            <div className="fixed bottom-20 left-1/2 flex w-[60%] -translate-x-1/2 transform flex-col items-center justify-center rounded-lg bg-base-100 py-3 shadow-md sm:flex-row md:gap-4 lg:w-fit lg:px-5">
              <div className="">{selectedIds.length} selected</div>
              <div className="text-2xl font-thin text-neutral">|</div>
              <button className="btn btn-ghost" onClick={() => setShowConfirmDelete(true)}>
                <FaTrash className="bg-clip-text text-purple-500" />
                <span className="bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
                  Delete
                </span>
              </button>
              {/* <button className="btn btn-ghost">
                <MdOutlineDriveFileMove size={22} className="bg-clip-text text-purple-500 " />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500">
                  Move to other List
                </span>
              </button> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default List;
