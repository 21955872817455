import { getCurrentQueryParams } from '@utils/queryParams';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkTabItem } from './types';

type UseLinkTabsArgs = {
  items: LinkTabItem[];
  initialTab?: string;
  stepName?: string;
};

export const useLinkTabs = ({ items, initialTab, stepName = 'tab' }: UseLinkTabsArgs) => {
  const { tab = initialTab || items[0].path } = getCurrentQueryParams<{ tab: string }>(window.location.search);

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(tab);

  const addQueryParam = (tab: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(stepName, tab);
    navigate(url.pathname + url.search);
  };

  const handleSelectTab = (tab: string) => {
    addQueryParam(tab);
    setCurrentTab(tab);
  };

  return {
    tab,
    currentTab,
    handleSelectTab,
  };
};
