import { useContext } from 'react';
import { SidebarActionsContext } from './SidebarContext';

export const useSidebarActions = () => {
  const context = useContext(SidebarActionsContext);

  if (!context) {
    throw new Error('useSidebarActions must be used within a SidebarProvider');
  }

  return context;
};
