import { cn } from '@/lib/utils';
import ComingSoonPage from '@/pages/ComingSoonPage';
import MyArea from '@/pages/MyArea/MyArea';
import MyCompanies from '@/pages/MyCompanies';
import Search from '@/pages/Search';
import { SignalsPage } from '@/pages/Signals';
import { SignalsIcon } from '@components/SvgIcon/Icons';
import { ContentError } from '@global/components/Layout/ContentError';
import { FaListUl, FaUsersViewfinder } from 'react-icons/fa6';
import { IoSearch } from 'react-icons/io5';
import { LuBuilding2 } from 'react-icons/lu';
import { RoutePath } from './constants';
import { SidebarBlock } from './types';

export const SIDEBAR_MODULES: SidebarBlock[] = [
  {
    title: 'explore',
    children: [
      {
        icon: IoSearch,
        path: RoutePath.HOME,
        shouldRevalidate: () => false,
        title: 'search',
        id: 'search',
        errorElement: <ContentError />,
        element: <Search />,
      },
      {
        icon: FaUsersViewfinder,
        path: RoutePath.INVESTORS,
        shouldRevalidate: () => false,
        title: 'investor_other',
        id: 'investor_other',
        errorElement: <ContentError />,
        element: <ComingSoonPage />,
      },
    ],
  },
  {
    title: 'signal_other',
    children: [
      {
        icon: ({ className }) => <SignalsIcon className={cn('size-[22px]', className)} />,
        path: RoutePath.SIGNALS,
        query: 'sorting=MostRecent',
        shouldRevalidate: () => false,
        title: 'allSignals',
        id: 'allSignals',
        errorElement: <ContentError />,
        element: <SignalsPage />,
      },
    ],
  },
  {
    title: 'myArea',
    children: [
      {
        icon: FaListUl,
        path: RoutePath.MY_AREA,
        shouldRevalidate: () => false,
        title: 'listsAndSearches',
        id: 'listsAndSearches',
        errorElement: <ContentError />,
        element: <MyArea />,
      },
      {
        icon: LuBuilding2,
        path: RoutePath.MY_COMPANIES,
        shouldRevalidate: () => false,
        title: 'company_other',
        id: 'company_other',
        errorElement: <ContentError />,
        element: <MyCompanies />,
      },
    ],
  },
];
