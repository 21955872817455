import { CSSProperties, FC } from 'react';
import { IconName } from './types';

export type SvgIconProps = {
  name: IconName;
  className?: string;
  style?: CSSProperties;
};

export const SvgIcon: FC<SvgIconProps> = ({ name, className, style }) => {
  const iconPath = `/icons/${name}.svg`;

  return <img src={iconPath} alt={name} className={className} style={style} />;
};
