import { OnChangeFn, RowSelectionState, SortingState } from '@tanstack/react-table';
import { Dispatch, ReactNode, RefObject } from 'react';
import { PaginationControlState } from './parts/Pagination/types';
import { Action, TableStateActions } from './reducer';
import { BaseVirtualTableAPI } from './types';

export const onSortingChangeHandler =
  <T extends { id?: string }>(
    sortingState: SortingState,
    tableRef: RefObject<BaseVirtualTableAPI>,
    dispatch: Dispatch<Action<T>>,
    onRowSelectionChange: OnChangeFn<RowSelectionState> | undefined,
  ): OnChangeFn<SortingState> =>
  (updater) => {
    if (typeof updater === 'function') {
      const nextState = updater(sortingState);
      dispatch({ type: TableStateActions.SET_SORTING, payload: nextState });
    } else {
      dispatch({ type: TableStateActions.SET_SORTING, payload: updater });
    }

    onRowSelectionChange?.({});
    tableRef.current?.scrollToOffset?.(0);
  };

export const paginationTableElement = <T extends { id?: string }>(
  paginationState: PaginationControlState,
  dispatch: Dispatch<Action<T>>,
  paginationRender:
    | undefined
    | ((pageProps: {
        paginationState: PaginationControlState;
        onPageStateChange: (state: PaginationControlState) => void;
      }) => ReactNode),
) => {
  const onPageStateChange = (state: PaginationControlState) => {
    dispatch({ type: TableStateActions.SET_PAGE_SIZE, payload: state.limit });
    dispatch({ type: TableStateActions.SET_PAGE_INDEX, payload: state.offset });
  };

  const paginationElement = paginationRender ? paginationRender({ paginationState, onPageStateChange }) : undefined;

  return {
    paginationElement,
  };
};
