import React, { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

export const MultiSelectLists = ({ lists, selectedLists, setSelectedLists, unavailableLists = [] }) => {
  const [displayText, setDisplayText] = useState(renderText(selectedLists));

  const handleToggleList = (list) => {
    setSelectedLists((prevState) => {
      const newSelectedLists = prevState.includes(list)
        ? prevState.filter((item) => item !== list)
        : [...prevState, list];

      setDisplayText(renderText(newSelectedLists));
      return newSelectedLists;
    });
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button className="select select-md bg-base-100 bordered border-1 border-base-300  h-10 items-center text-sm ">
          {displayText}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="px-4 bg-base-100 shadow-md rounded-lg text-xs w-[200px] z-[10005] max-h-[160px] overflow-y-auto"
          sideOffset={10}
          align="start"
        >
          {lists.map((list) => (
            <div key={list.name} className="flex items-center my-2 w-full">
              <input
                disabled={unavailableLists.includes(list.id)}
                type="checkbox"
                checked={selectedLists.includes(list) || unavailableLists.includes(list.id)}
                onChange={() => handleToggleList(list)}
                className="checkbox size-[17px] rounded-md"
              />
              <label className="ml-2">{list.name}</label>
            </div>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const renderText = (lists) => {
  if (lists.length === 0) {
    return <span className="opacity-70">Create a new list</span>;
  } else if (lists.length === 1) {
    return lists[0].name;
  } else {
    return `${lists.length} selected`;
  }
};
