import { useEffect, useId, useState, useTransition } from 'react';
import { TabItem } from './types';

type UseTabsArgs = {
  items: TabItem[];
  onSelectTab?: (tab: TabItem) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  currentTab?: string;
  disabled?: boolean;
};

export const useTabs = ({ items, onSelectTab, onKeyDown, currentTab, disabled }: UseTabsArgs) => {
  const tabListId = useId();

  const [_, startTransition] = useTransition();
  const preSelectedItem = items.findIndex((item) => item.title === currentTab);
  const [openTab, setOpenTab] = useState(preSelectedItem !== -1 ? preSelectedItem : 0);

  useEffect(() => setOpenTab(preSelectedItem !== -1 ? preSelectedItem : 0), [items]);

  const selectTab = (tabIndex: number) => {
    if (!disabled) startTransition(() => setOpenTab(tabIndex));
    onSelectTab?.(items[tabIndex]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    let flag = false;
    const links = Array.from(document.querySelectorAll(`[role="tab"][id^="tab-${tabListId}-"]`));

    switch (event.key) {
      case 'ArrowLeft':
        (
          links[
            (links.findIndex(
              (link) => link.getAttribute('data-index') === event.currentTarget.getAttribute('data-index'),
            ) -
              1 +
              links.length) %
              links.length
          ] as HTMLButtonElement
        ).focus();
        flag = true;
        break;

      case 'ArrowRight':
        (
          links[
            (links.findIndex(
              (link) => link.getAttribute('data-index') === event.currentTarget.getAttribute('data-index'),
            ) +
              1) %
              links.length
          ] as HTMLButtonElement
        ).focus();
        flag = true;
        break;

      case 'ArrowDown':
        (
          links[
            (links.findIndex(
              (link) => link.getAttribute('data-index') === event.currentTarget.getAttribute('data-index'),
            ) +
              1) %
              links.length
          ] as HTMLButtonElement
        ).focus();
        flag = true;
        break;

      case 'ArrowUp':
        (
          links[
            (links.findIndex(
              (link) => link.getAttribute('data-index') === event.currentTarget.getAttribute('data-index'),
            ) -
              1 +
              links.length) %
              links.length
          ] as HTMLButtonElement
        ).focus();
        flag = true;
        break;

      case 'Home':
        (links[0] as HTMLButtonElement).focus();
        flag = true;
        break;

      case 'End':
        (links[links.length - 1] as HTMLButtonElement).focus();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }

    onKeyDown?.(event);
  };

  return {
    openTab,
    selectTab,
    handleKeyDown,
    tabListId,
  };
};
