import { GetURLFilterArgs, GetURLValueArgs } from './types';

const getURLValue = ({ searchParams, field }: GetURLValueArgs) => searchParams.get(field) ?? '';

export const getURLStringFilter = (args: GetURLValueArgs) => getURLValue(args);

export const getURLNumberFilter = (args: GetURLValueArgs) => Number(getURLValue(args));

export const getURLBooleanFilter = (args: GetURLValueArgs) => {
  const value = getURLValue(args);

  return value ? value === 'true' : null;
};

export const getURLSelectFilter = <TReturn>(args: GetURLValueArgs) => getURLValue(args) as TReturn;

export const getURLMultiSelectFilter = <TReturn>(args: GetURLValueArgs) => {
  const value = getURLValue(args);

  return value.includes(',') ? value.split(',').map((item) => item as TReturn) : [];
};

export const getURLFilter = ({ type, ...rest }: GetURLFilterArgs) => {
  switch (type) {
    case 'string':
      return getURLStringFilter(rest);

    case 'number':
      return getURLNumberFilter(rest);

    case 'boolean':
      return getURLBooleanFilter(rest);

    case 'select':
      return getURLSelectFilter<string>(rest);

    case 'multiSelect':
      return getURLMultiSelectFilter<string>(rest);

    default:
      return getURLValue(rest);
  }
};
