import { api } from '@/api/apiV2';
import { QueryResponse } from '@/api/generated/Api';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseQueriesArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: QueryResponse[]) => void;
};

export const useQueries = ({ signal, onSuccess }: UseQueriesArgs) => {
  const { data, isFetching } = useQuery<QueryResponse[]>({
    queryKey: [QueryKeys.Queries],
    queryFn: async () => {
      const response = await api.query.queriesList({
        signal,
      });

      const result = response.data;
      onSuccess?.(result);

      return result;
    },
  });

  return {
    queries: data ?? [],
    isQueriesLoading: isFetching,
  };
};
