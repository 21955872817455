import { ServerTable } from '@components/Tables/ServerTable/ServerTable';
import { TableDataResponse, TableRequestBody } from '@components/Tables/types';
import { RowSelectionState } from '@tanstack/react-table';
import { FC, useState } from 'react';
import {
  PaymentHistoryResponse,
  PaymentStatus,
  usePaymentHistoryTableInitialState,
} from './hooks/usePaymentHistoryTableInitialState';

const randomEnumValue = (ob: { [key in string]: key }) => {
  const values = Object.keys(ob);
  const enumKey = values[Math.floor(Math.random() * values.length)];

  return ob[enumKey];
};

const TEST_PAYMENT_HISTORY_RESPONSE: PaymentHistoryResponse[] = [...Array(10).keys()].map(
  (num) =>
    ({
      id: num.toString(),
      amount: num,
      date: num.toString(),
      description: num.toString(),
      status: randomEnumValue(PaymentStatus) as PaymentStatus,
    }) satisfies PaymentHistoryResponse,
);

export const PaymentHistoryTable: FC = () => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const initialState = usePaymentHistoryTableInitialState();

  const fetcher = async (options: { params: TableRequestBody }) => {
    console.log(options.params);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // return [] as MyResponse[];
    return {
      data: TEST_PAYMENT_HISTORY_RESPONSE,
      // totalRecords: TEST_PAYMENT_HISTORY_RESPONSE.length * 10,
      totalRecords: 25 * 6,
    } satisfies TableDataResponse<PaymentHistoryResponse>;
  };

  return (
    <ServerTable<PaymentHistoryResponse>
      id="paymentHistory"
      fetcher={fetcher}
      initialState={initialState}
      queryKey={['PaymentHistoryResponse']}
      usePagination
      className="max-h-[650px]"
      rowSelection={rowSelection}
      onRowSelectionChange={setRowSelection}
      enableMultiRowSelection
    />
  );
};
