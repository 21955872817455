import { TFileType } from './types';

export const ALLOWED_IMAGE_TYPES: TFileType[] = [
  {
    extension: '.jpeg',
    mimeTypes: ['image/jpeg'],
  },
  {
    extension: '.png',
    mimeTypes: ['image/png'],
  },
  {
    extension: '.jpeg',
    mimeTypes: ['image/jpeg'],
  },
];
