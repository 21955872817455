import { TranslationKeys } from '@/i18n';
import { getURLSelectFilter, setURLSelectFilter } from '@global/filters';
import { Option } from '@global/types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';

type SelectFilterProps<T extends string> = {
  field: string;
  options: Option<T>[];
  placeholder?: TranslationKeys;
};

export const SelectFilter = <T extends string>({
  field,
  options,
  placeholder = 'selectFilter',
}: SelectFilterProps<T>) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const value = getURLSelectFilter<T>({
    field,
    searchParams,
  });
  const optionValue = options.find((x) => x.value === value);

  const handleChange = (option: SingleValue<Option<T>>) => {
    if (option) {
      setURLSelectFilter({
        field,
        searchParams,
        value: option,
      });
    } else {
      searchParams.delete(field);
    }

    setSearchParams(searchParams, {
      replace: true
    });
  };

  return (
    <Select
      options={options}
      name="selectedRegion"
      value={optionValue}
      className="mt-2 w-[215px] rounded-lg bg-base-200 drop-shadow-md"
      isSearchable={false}
      placeholder={t(placeholder)}
      isClearable
      escapeClearsValue
      backspaceRemovesValue
      getOptionLabel={(option) => t(option.label)}
      classNames={{
        option: (state) =>
          `${state.isSelected ? '!bg-gradient-to-r from-gradient-from to-gradient-to' : ''} !cursor-pointer`,
      }}
      styles={{
        control: (base) => ({
          ...base,
          fontSize: '12px',
          borderColor: 'transparent',
          borderRadius: '8px',
          height: '100%',
          paddingInline: '12px',
          cursor: 'pointer',
          textTransform: 'capitalize',
        }),
        menuList: (base) => ({
          ...base,
          fontSize: '12px',
          textTransform: 'capitalize',
          fontWeight: 700,
        }),
        option: (base) => ({
          ...base,
          ':hover': {
            backgroundColor: '#E1E5ED',
          },
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0px',
          fontWeight: 700,
        }),
        placeholder: (base) => ({
          ...base,
          margin: '0px',
          fontSize: '12px',
          color: '#4D5E80',
          fontWeight: 700,
        }),
        singleValue: (base) => ({
          ...base,
          color: '#4D5E80',
        }),
      }}
      onChange={handleChange}
      menuPortalTarget={document.body}
    />
  );
};
