// src/hooks/useSearchForm.js
import { MIN_LIMIT, MIN_OFFSET } from '@components/Pagination/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { httpSaveSearchToMyArea, httpSearchCompanies } from '../api/CompaniesAPI';
import { buildQueryParams, resetFormData } from '../utils/utils';

const useSearchForm = (triggerSearchOnLoad = true, path = '/search', apiEndpoint = '/search') => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inputError, setInputError] = useState('');
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [saved, setSaved] = useState(false);

  const Navigate = useNavigate();

  const [formData, setFormData] = useState(resetFormData(searchParams));

  useEffect(() => {
    setSaved(false);
  }, [formData]);

  const handleChangeQuery = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeToggle = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      type: event.target.checked,
      offset: MIN_OFFSET,
      limit: MIN_LIMIT,
    }));
  };

  const isQueryValid = () => {
    if (formData.query.length < 3 && formData.query.length >= 0) {
      setInputError('Please enter at least 3 characters to search');
      return false;
    } else {
      setInputError('');
      return true;
    }
  };

  const search = () => {
    setIsLoading(true);
    httpSearchCompanies(formData, apiEndpoint)
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveToMyArea = async (title, signalTypes) => {
    try {
      await httpSaveSearchToMyArea(formData, title, signalTypes);

      setSaved(true);
    } catch (error) {
      console.error(error);
    }
  };

  const go = (event) => {
    event.preventDefault();

    if (isQueryValid()) {
      const queryParams = buildQueryParams(formData);
      Navigate(`${path}?${queryParams.toString()}`);
      setShouldSearch(true);
    } else if (apiEndpoint.includes('similar-companies')) {
      const queryParams = buildQueryParams(formData);
      Navigate(`${path}?tab=similar&${queryParams.toString()}`);
      setShouldSearch(true);
    } else {
      setInputError('Please enter at least 3 characters to search');
    }
  };

  useEffect(() => {
    setFormData(resetFormData(searchParams));
    if (triggerSearchOnLoad) {
      setShouldSearch(true);
    }
  }, [searchParams, triggerSearchOnLoad]);

  useEffect(() => {
    if (shouldSearch) {
      search();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const setPage = (newOffset) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: newOffset,
    });
  };

  const setPageSize = (limit) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: MIN_OFFSET,
      limit: limit,
    });
  };

  return {
    formData,
    setFormData,
    handleChangeQuery,
    handleChangeToggle,
    isQueryValid,
    search,
    saveToMyArea,
    saved,
    go,
    isLoading,
    response,
    inputError,
    showAddToListPopup,
    setShowAddToListPopup,
    showFilters,
    setShowFilters,
    selectedIds,
    setSelectedIds,
    setPage,
    setPageSize,
  };
};

export default useSearchForm;
