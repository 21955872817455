import { type FC, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { styles } from './styles';
import type { TextSizes, TextWeights, TypographyProps } from './types';

export const Typography: FC<TypographyProps> = ({
  text,
  tag = 'p',
  variant = 'base/regular',
  interpolationsParams = {},
  className,
}) => {
  const truncatedClasses = ['truncate', 'text-ellipsis', 'text-clip', 'line-clamp'];
  const { t } = useTranslation();
  const translatedText = t(text, { ...interpolationsParams });

  if (tag === 'none') return <>{translatedText}</>;

  const [size, weight] = variant.split('/') as [TextSizes, TextWeights];

  return createElement(
    tag,
    {
      title: truncatedClasses.some((c) => className?.includes(c)) ? translatedText : undefined,
      className: twMerge(styles({ size, weight }), className),
    },
    translatedText,
  );
};
