import { cn } from '@/lib/utils';
import { VariantProps } from 'class-variance-authority';
import { FC } from 'react';
import { styles } from './Spinner.styles';

type SpinnerProps = {
  intent?: VariantProps<typeof styles>['intent'];
  size?: VariantProps<typeof styles>['size'];
  className?: string;
};

export const Spinner: FC<SpinnerProps> = ({ intent = 'accent', size = 'xl', className }) => (
  <div className={cn(styles({ intent, size }), className, '')}>
    <div className="size-full rounded-full bg-white" />
    <span className="sr-only">Loading...</span> 
  </div>
);
