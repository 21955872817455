import { useSignals } from '@/api/entities/signals';
import { useAbortController } from '@/hooks';
import AddToListPopup from '@components/AddToListPopup';
import { Spinner } from '@components/Spinner';
import { Loadable } from '@global/components/Loadable';
import { useURLFilters } from '@global/filters';
import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSignalsPageActions, useSignalsPageValues } from '../context';
import { SIGNAL_FILTERS_CONFIGURATION } from './Filters';
import { SignalsGroup } from './SignalsGroup';
import { SignalsNotFound } from './SignalsNotFound';

export const SignalsList: FC = () => {
  const { addToListCompanyId } = useSignalsPageValues();
  const { closeAddToListPopup } = useSignalsPageActions();

  const filters = useURLFilters(SIGNAL_FILTERS_CONFIGURATION);
  const { signal } = useAbortController();

  const { data, isFetching, isFetchingNextPage, isEmpty, fetchNextPage, hasNextPage } = useSignals({
    filters,
    signal,
  });

  const { ref, inView } = useInView({
    threshold: 0.85,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <>
      <div className="flex h-[66dvh] grow flex-col gap-3 overflow-y-auto pe-2 outline-none">
        <Loadable isLoading={isFetching && !isFetchingNextPage}>
          {isEmpty ? (
            <SignalsNotFound />
          ) : (
            data?.pages.flatMap((page) =>
              page.signalsGroups.map((group) => <SignalsGroup key={group.date} group={group} />),
            )
          )}
        </Loadable>
        {(hasNextPage ?? !isFetching) ? (
          <div ref={ref} className="flex h-14 shrink-0 justify-center pt-3">
            {isFetchingNextPage ? <Spinner size="base" /> : null}
          </div>
        ) : null}
      </div>
      {addToListCompanyId ? (
        <AddToListPopup handleModalToggle={closeAddToListPopup} companiesIds={[addToListCompanyId]} />
      ) : null}
    </>
  );
};
