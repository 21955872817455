import { Typography } from '@components/Typography';
import { FC } from 'react';
import { EducationTable } from '../EducationTable';

type EducationMetricModalContentProps = {};

export const EducationMetricModalContent: FC<EducationMetricModalContentProps> = ({}) => {
  return (
    <div className="flex flex-col gap-6">
      <header className="contents">
        <Typography text={'education'} variant="lg/bold" className="capitalize leading-[30px] text-grey-900" />
        {/* <div className="flex items-end gap-2 *:text-grey-900">
          <Typography
            text={'tierN'}
            interpolationsParams={{ count: 'n/d' }}
            variant="doubleXl/bold"
            className="leading-[22px] first-letter:capitalize"
          />
          <Typography text={'howIsItCalculated'} variant="xs/regular" className="underline" />
        </div> */}
      </header>
      <EducationTable />
    </div>
  );
};
