import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import type { FC } from 'react';
import { TabsProps } from './types';
import { useTabs } from './useTabs';

export const Tabs: FC<TabsProps> = ({
  items,
  onSelectTab,
  className,
  navClassName,
  title,
  onKeyDown,
  currentTab,
  disabled,
}) => {
  const { tabListId, handleKeyDown, openTab, selectTab } = useTabs({
    items,
    onSelectTab,
    onKeyDown,
    currentTab,
    disabled,
  });

  return (
    <section className={cn('flex h-full w-full flex-col', className)}>
      {title ? (
        <span id={`tablist-${tabListId}`} className="sr-only">
          {title}
        </span>
      ) : null}
      <nav
        aria-labelledby={title ? `tablist-${tabListId}` : undefined}
        role="tablist"
        className={cn('mb-[23px] flex w-full max-w-full flex-wrap', navClassName)}
      >
        {items.map((item, index) => (
          <button
            onKeyDown={handleKeyDown}
            id={`tab-${tabListId}-${index}`}
            type="button"
            role="tab"
            aria-selected={openTab === index}
            aria-controls={`tabpanel-${index}-${tabListId}`}
            tabIndex={openTab === index ? undefined : -1}
            key={index}
            disabled={disabled}
            data-index={index}
            className="group inline-block shrink-0 cursor-pointer px-5 first:ps-0 focus:outline-none focus-visible:underline disabled:cursor-not-allowed motion-safe:transition-colors"
            onClick={() => selectTab(index)}
          >
            <Typography
              arbitrary
              tag="h2"
              text={item.title}
              variant="doubleXl/regular"
              className="group-aria-[selected=true] text-grey-900 first-letter:capitalize group-aria-[selected=true]:font-bold group-aria-[selected=true]:[text-shadow:0px_5px_4px_#d0d1d3]"
            />
          </button>
        ))}
      </nav>
      <div
        aria-labelledby={`tab-${tabListId}-${items.findIndex((item) => item.title === items[openTab].title)}`}
        role="tabpanel"
        id="tab-content"
        className="flex h-full flex-col"
      >
        {items[openTab].element}
      </div>
    </section>
  );
};
