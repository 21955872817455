import React from 'react';
import { IoClose } from 'react-icons/io5';

const ConfirmPopup = ({ isOpen, onClose, onConfirm, title, children, confirmText, cancelText }) => {
  const handleClose = (e) => {
    if (e.target.id === 'ModalContainer') {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-[2000]"
      id="ModalContainer"
      onClick={handleClose}
    >
      <div className="rounded-lg w-[80%] lg:w-[40%] bg-base-200" onClick={(e) => e.stopPropagation()}>
        <div className="rounded-tl-lg rounded-tr-lg w-full h-10 bg-custom-gradient flex justify-end items-center">
          <IoClose size={20} className="mr-2 text-base-200 cursor-pointer" onClick={onClose} />
        </div>
        <div className="rounded-lg w-full flex justify-center items-center gap-4 flex-col px-10 mt-7 mb-10">
          <h3 className="text-xl font-bold">{title}</h3>
          <div>{children}</div>
          <div className="flex justify-center items-center gap-4">
            <button onClick={onClose} className="btn w-1/2 bg-neutral text-base-content">
              {cancelText}
            </button>
            <button onClick={onConfirm} className="btn w-1/2 bg-error text-base-200">
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
