import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';

type InfoCardContentItemContentProps = {
  text: string;
  className?: string;
};

export function InfoCardContentItemContent({ text, className }: InfoCardContentItemContentProps) {
  return <Typography arbitrary text={text} variant="xs/regular" className={cn('line-clamp-2 break-all', className)} />;
}
