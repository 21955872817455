import { SignalType } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { SignalTypesData } from '@/pages/Signals/constants';
import { Typography } from '@components/Typography';
import { FC } from 'react';

type SignalTypeTagProps = {
  type: SignalType;
};

export const SignalTypeTag: FC<SignalTypeTagProps> = ({ type }) => {
  const data = SignalTypesData.get(type);

  return (
    <Typography
      arbitrary
      text={type}
      className={cn(
        'h-[18px] rounded-full px-3 py-1 text-[10px] leading-[10px] capitalize',
        `text-${data?.tagTextColor} bg-${data?.tagBackgroundColor}`,
      )}
    />
  );
};
