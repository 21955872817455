import { SidebarBlock as SidebarBlockType } from '@/router/types';
import { Typography } from '@components/Typography';
import { FC } from 'react';
import { TileLayout } from '../../../../TileLayout';
import { SidebarItem } from '../SidebarItem';

type SidebarBlockProps = {
  module: SidebarBlockType;
};

export const SidebarBlock: FC<SidebarBlockProps> = ({ module }) => (
  <TileLayout className="px-3 py-5 sidebar-closed:gap-0 sidebar-closed:bg-inherit sidebar-closed:p-0 sidebar-closed:shadow-none motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none">
    <Typography
      text={module.title}
      className="h-0 px-2 uppercase text-grey-700 opacity-0 sidebar-expanded:h-6 sidebar-expanded:opacity-100 motion-safe:transition-all motion-safe:duration-[350ms] motion-reduce:transition-none"
    />
    <ul className="flex shrink-0 flex-col gap-1">
      {module.children.map((route) => (
        <SidebarItem key={route.id} item={route} />
      ))}
    </ul>
  </TileLayout>
);
