import { useSearchParams } from 'react-router-dom';
import { getURLFilter } from './getURLFilters';
import { FiltersConfiguration } from './types';

export const useURLFilters = <TFilter extends Record<string, unknown>>(
  config: FiltersConfiguration<TFilter>,
): TFilter => {
  const [searchParams, _] = useSearchParams();

  return Object.entries(config).reduce((acc, [field, type]) => {
    const filter = getURLFilter({ searchParams, field, type });

    if (filter !== undefined && filter !== '') {
      //@ts-expect-error
      acc[field as keyof TFilter] = filter;
    }

    return acc;
  }, {} as TFilter);
};
