import { Typography } from '@components/Typography';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';
import { PaymentHistoryTable } from './parts/PaymentHistoryTable/PaymentHistoryTable';

export const PaymentHistory: FC = () => {
  return (
    <TileLayout>
      <Typography text={'paymentHistory'} variant="lg/bold" className="capitalize text-grey-900" />
      <PaymentHistoryTable />
    </TileLayout>
  );
};
