import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

type InfoCardBodyProps = {
  className?: string;
} & PropsWithChildren;

export function InfoCardBody({ className, children }: InfoCardBodyProps) {
  return <div className={cn('flex grow flex-col gap-3', className)}>{children}</div>;
}
