import { useEffect, useState } from 'react';
import { useLists } from '../contexts/ListContext';
import { IoClose } from 'react-icons/io5';
import { FaSave } from 'react-icons/fa';
import { httpCreateUserList } from '../api/UserListsAPI';

function CreateListPopup({ handleModalToggle, fetchLists }) {
  const { lists, createList } = useLists();
  const [newListName, setNewListName] = useState('');
  const [newListNote, setNewListNotes] = useState('');

  const [error, setError] = useState('');

  const validateListName = () => {
    if (newListName.length < 4) {
      setError('List name must be at least 4 characters long.');
    } else {
      setError('');
    }
  };

  const handleListNameChange = (e) => {
    setNewListName(e.target.value);
  };

  const handleListNotesChange = (e) => {
    setNewListNotes(e.target.value);
  };

  const handleCreateList = () => {
    httpCreateUserList({ name: newListName, notes: newListNote, userId: localStorage.getItem('userId') })
      .then((res) => {
        handleModalToggle();
        setNewListName('');
        fetchLists();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selected, setSelected] = useState('');

  const handleChangeListSelected = (event) => {
    setSelected(event.target.value);
  };

  const handlClosePopUp = (e) => {
    if (e.target.id === 'ModelContainer') {
      handleModalToggle();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-[2000]"
      id="ModelContainer"
      onClick={handlClosePopUp}
    >
      <div className="rounded-lg w-[80%] lg:w-[50%]  bg-base-200">
        <div className="rounded-tl-lg rounded-tr-lg w-full h-10 bg-custom-gradient flex justify-end items-center">
          <IoClose size={20} className="mr-2 text-base-200 cursor-pointer" onClick={handleModalToggle} />
        </div>

        <div
          className={`${
            selected ? 'opacity-50 pointer-events-none' : ''
          } rounded-lg w-full flex justify-center items-start flex-col px-10 mt-7 mb-10`}
        >
          <h2 className="text-2xl font-bold mb-4">Create new list</h2>
          <div className="label">
            <span className="label-text text-base font-semibold opacity-70">
              List Name <span className="text-red-500">*</span>
            </span>
          </div>
          <input
            type="text"
            placeholder="Enter list name..."
            value={newListName}
            onChange={handleListNameChange}
            className={'w-full input input-bordered' + `${error && 'border-1 border-error'}`}
            onBlur={validateListName}
          />
          {error && <p className="text-error text-xs mt-2">{error}</p>}
          <div className="label">
            <span className="label-text text-base font-semibold opacity-70 mt-4">Notes</span>
          </div>
          <textarea
            maxLength="255"
            className="w-full textarea textarea-bordered mb-4"
            onChange={handleListNotesChange}
            placeholder="Insert notes here"
            rows="4"
          ></textarea>
        </div>

        <div className="flex justify-center items-center gap-4 mb-10">
          <button
            onClick={handleCreateList}
            disabled={selected == '' && newListName.length < 4}
            className={`"btn w-1/2  ${
              selected == '' && newListName.length < 4
                ? 'bg-neutral text-base-content'
                : 'bg-custom-gradient text-base-200'
            } btn flex items-center  shadow-md hover:scale-102 transition-transform"`}
          >
            <FaSave size={20} className="" />
            <span className="text-[18px] font-medium">Save</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateListPopup;
