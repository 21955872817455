import { api } from '@/api/apiV2';
import { UpdateUserRequest } from '@/api/generated/Api';
import { useMutation } from '@tanstack/react-query';

type UseUpdateUserArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: UpdateUserRequest) => void;
};

export const useUpdateUser = ({ signal, onSuccess }: UseUpdateUserArgs) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['update-user'],
    mutationFn: async (data: UpdateUserRequest) => {
      await api.accounts.accountsUpdate(data, { signal });

      onSuccess?.(data);
    },
  });

  return {
    updateUser: mutate,
    isUserUpdating: isPending,
  };
};
