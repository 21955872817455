import { selectItemFormScheme } from '@global/validators';
import { z } from 'zod';

export const saveQueryFormSchema = z
  .object({
    query: z.string().min(2).max(1000),
    useTriggers: z.boolean().nullable(),
    triggers: z.array(selectItemFormScheme),
  })
  .refine((val) => !val.useTriggers || (val.triggers && val.triggers.length > 0), {
    message: 'Triggers are required',
    path: ['triggers'],
  })
  .transform((val) => {
    const { query, triggers, useTriggers } = val;

    return {
      query,
      triggers,
    };
  });

export type SaveQueryInput = z.input<typeof saveQueryFormSchema>;
export type SaveQueryOutput = z.output<typeof saveQueryFormSchema>;
