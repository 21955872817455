import { api } from '@/api/apiV2';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type useTeamMetricsArgs = {
  companyId: string;
  signal: AbortSignal;
};

export const useTeamMetrics = ({ companyId, signal }: useTeamMetricsArgs) =>
  useQuery({
    queryKey: [QueryKeys.TeamMetrics, companyId],
    queryFn: async () => {
      const result = await api.teams.getCompanyTeamMetrics(companyId, {
        signal,
      });

      return result.data;
    },
  });
