import { customAxios } from '@/api/customAxios';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseUserInfoArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: File) => void;
};

export const useProfileImage = ({ signal, onSuccess }: UseUserInfoArgs) => {
  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.ProfileImage],
    queryFn: async () => {
      const response = await customAxios.get('api/documents/profile-image', {
        signal,
        responseType: 'blob',
      });

      if (response.status >= 200 && response.status < 300) {
        const result = response.data as unknown as File;
        onSuccess?.(result);

        return result;
      }

      return null;
    },
    staleTime: Infinity,
  });

  return {
    profileImage: data,
    isProfileImageLoading: isFetching,
  };
};
