import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { FC } from 'react';

export const SignalsNotFound: FC = () => (
  <div className="flex grow flex-col items-center justify-center gap-4">
    <img src="/signals.png" alt="signals_logo" className="size-14" />
    <Typography text={'noSignalsYet'} tag="h2" className="text-5xl font-bold text-grey" />
    <div className="flex flex-col items-center *:first-letter:capitalize">
      <Typography text={'signalsAre'} variant="lg/regular" className="text-grey" />
      <Typography text={'setYourRulesAndGetNewSignalsEveryDay'} variant="lg/regular" className="text-grey" />
    </div>
    <Button text="goToSignalSettings" intent="secondary" className="px-6 py-[10px]" />
  </div>
);
