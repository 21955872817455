import { SignalResponse } from '@/api/generated/Api';
import { InfoCard } from '@global/components/InfoCard';
import { FC } from 'react';

type SignalCardFooterProps = {
  signal: SignalResponse;
};

export const SignalCardFooter: FC<SignalCardFooterProps> = ({ signal }) => (
  <InfoCard.Footer>
    <InfoCard.FooterIcon icon="LinkedIn" src={signal.company.linkedinUrl} />
    <InfoCard.FooterIcon icon="Globe" src={signal.company.websiteUrl} />
  </InfoCard.Footer>
);
