import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

type SignalsValuesApi = {
  addToListCompanyId: string | null;
};

type SignalsActionsApi = {
  openAddToListPopup: (value: string) => void;
  closeAddToListPopup: VoidFunction;
};

export const SignalsContext = createContext<SignalsValuesApi | null>(null);
export const SignalsActionsContext = createContext<SignalsActionsApi | null>(null);

export const SignalsPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [addToListCompanyId, setAddToListCompanyId] = useState<string | null>(null);

  const openAddToListPopup = useCallback((id: string) => {
    setAddToListCompanyId(id);
  }, []);

  const closeAddToListPopup = useCallback(() => {
    setAddToListCompanyId(null);
  }, []);

  const value = useMemo(
    () =>
      ({
        addToListCompanyId,
      }) satisfies SignalsValuesApi,
    [addToListCompanyId],
  );

  const actions = useMemo(
    () =>
      ({
        openAddToListPopup,
        closeAddToListPopup,
      }) satisfies SignalsActionsApi,
    [openAddToListPopup, closeAddToListPopup],
  );

  return (
    <SignalsContext.Provider value={value}>
      <SignalsActionsContext.Provider value={actions}>{children}</SignalsActionsContext.Provider>
    </SignalsContext.Provider>
  );
};
