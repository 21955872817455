// TODO: Should be inferred from openapi
export enum CreditCardType {
  Unknown = 'Unknown',
  Visa = 'Visa',
  MasterCard = 'MasterCard',
}

// TODO: Should be inferred from openapi
export type CreditCardResponse = {
  id: string;
  type: CreditCardType;
  last4Digits: number;
  expirationDate: string;
};
