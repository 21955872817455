import axios from 'axios';
import { customAxios } from './customAxios';

const PATH = '/api/queries';
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

export function httpGetUserQueries() {
  return customAxios.get(`${API_ENDPOINT}${PATH}`);
}

export function httpDeleteQuery(id) {
  return customAxios.delete(`${API_ENDPOINT}${PATH}/${id}`);
}
