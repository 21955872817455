import { useTeamMetrics } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { TeamMetric } from '../parts/TeamMetric';

export const FoundersMarketFitMetric: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { data } = useTeamMetrics({
    companyId: id as string,
    signal,
  });

  const value = 51;
  const isAboveAverage = value > 50;

  return (
    <TeamMetric icon="DottetCircleLeft" disabled title="Coming soon">
      <TeamMetric.Title
        // title={{
        //   text: 'nPercentage',
        //   interpolationsParams: { count: 'n/d' },
        // }}
        arbitrary
        text="n/d"
        className="first-letter:normal-case"
      />
      <TeamMetric.Subtitle
        subtitle={{
          text: 'foundersMarketFit',
        }}
      />
      <Typography
        text={isAboveAverage ? 'aboveAverage' : 'belowAverage'}
        variant="xs/semibold"
        className={cn('leading-[22px] first-letter:capitalize', isAboveAverage ? 'text-success' : 'text-red-500')}
      />
    </TeamMetric>
  );
};
