import { useSidebarValues } from '@/contexts/SidebarContext/useSidebarValues';
import { auth } from '@/lib/firebase';
import { SIDEBAR_MODULES } from '@/router/sidebar';
import { signOut } from 'firebase/auth';
import { FC, useEffect } from 'react';
import { UserInfo } from './parts';
import { SidebarBlock } from './parts/SidebarBlock/SidebarBlock';

export const Sidebar: FC = () => {
  const { isExpanded } = useSidebarValues();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      window.location.href = '/login';
    }

    if (!localStorage.getItem('refresh-token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('userId');
      localStorage.removeItem('email');
      signOut(auth);

      window.location.href = '/login';
    }
  }, []);

  return (
    <aside
      aria-expanded={isExpanded}
      className="group/sidebar peer absolute z-50 h-main w-sidebar-short px-5 py-4 aria-expanded:w-sidebar-full aria-expanded:p-4 motion-safe:transition-all motion-safe:duration-500"
    >
      <nav className="flex size-full flex-col justify-between">
        <ul className="flex flex-col gap-5 sidebar-expanded:gap-[14px] motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none">
          {SIDEBAR_MODULES.map((block) => (
            <SidebarBlock key={block.title} module={block} />
          ))}
        </ul>
        <UserInfo />
      </nav>
    </aside>
  );
};
