import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

const MarkdownText = ({ text }) => {
  const [cleanText, setCleanText] = useState('');

  useEffect(() => {
    const cleanText = text?.replace(/\\n/g, '\n');
    setCleanText(cleanText);
  }, [text]);

  return (
    <div className="text-sm px-4 " style={{ lineHeight: '24px' }}>
      <ReactMarkdown
        children={cleanText}
        remarkPlugins={[remarkBreaks, remarkGfm]}
        components={{
          a: ({ node, ...props }) => (
            <a {...props} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer" />
          ),
        }}
      />
    </div>
  );
};

export default MarkdownText;
