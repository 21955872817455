import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

type SidebarValuesApi = {
  isExpanded: boolean;
};

type SidebarActionsApi = {
  toggleSidebar: VoidFunction;
};

export const SidebarContext = createContext<SidebarValuesApi | null>(null);
export const SidebarActionsContext = createContext<SidebarActionsApi | null>(null);

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialIsPinned =
    typeof localStorage.getItem('sidebar') === 'object' ? true : !!localStorage.getItem('sidebar');

  const [isExpanded, setIsExpanded] = useState(initialIsPinned);

  const toggleSidebar = useCallback(() => {
    if (!isExpanded) {
      localStorage.setItem('sidebar', 'opened');
    } else {
      localStorage.setItem('sidebar', '');
    }
    setIsExpanded((v) => !v);
  }, [isExpanded]);

  const value = useMemo(
    () =>
      ({
        isExpanded: isExpanded,
      }) satisfies SidebarValuesApi,
    [isExpanded],
  );

  const actions = useMemo(
    () =>
      ({
        toggleSidebar,
      }) satisfies SidebarActionsApi,
    [toggleSidebar],
  );

  return (
    <SidebarContext.Provider value={value}>
      <SidebarActionsContext.Provider value={actions}>{children}</SidebarActionsContext.Provider>
    </SidebarContext.Provider>
  );
};
