import { TableElement } from '@components/Tables/types';
import type { ColumnDef, Table } from '@tanstack/react-table';
import { TableCheckbox } from './TableCheckbox';

type CheckboxColumnProps<T extends TableElement> = {
  table: Table<T>;
  enableMultiRowSelection?: boolean;
};

export const selectionColumn = <T extends Record<string, unknown>>({
  table,
  enableMultiRowSelection,
}: CheckboxColumnProps<T>): ColumnDef<T> => ({
  header: () =>
    enableMultiRowSelection ? (
      <TableCheckbox checked={table.getIsAllPageRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
    ) : (
      ''
    ),
  cell: ({ row }) => <TableCheckbox checked={row.getIsSelected()} onChange={() => row.toggleSelected()} />,
  size: 40,
  minSize: 40,
  maxSize: 40,
  id: 'isSelected',
});
