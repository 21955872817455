import ComingSoonPage from '@/pages/ComingSoonPage';
import Company from '@/pages/Company/Company';
import Home from '@/pages/Home';
import List from '@/pages/List';
import Login from '@/pages/Login';
import MyLists from '@/pages/MyLists';
import PasswordRecovery from '@/pages/PasswordRecovery';
import Profile from '@/pages/Profile/Profile';
import Search from '@/pages/Search';
import Signup from '@/pages/Signup';
import { ContentError } from '@global/components/Layout/ContentError';
import { ErrorPage } from '@global/components/Layout/ErrorPage';
import { Layout } from '@global/components/Layout/Layout';
import { Navigate } from 'react-router-dom';
import { RoutePath } from './constants';
import { SIDEBAR_MODULES } from './sidebar';

export const getRouter = (isLoggedIn: boolean) => {
  if (!isLoggedIn) {
    return [
      { path: '*', element: <Navigate to={RoutePath.LOGIN} state={{ prevUrl: window.location.href }} replace /> },
      {
        path: RoutePath.LOGIN,
        errorElement: <ErrorPage />,
        element: <Login />,
      },
      {
        path: RoutePath.SIGN_UP,
        id: 'signUp',
        element: <Signup />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.PASSWORD_RECOVERY,
        id: 'passwordRecovery',
        element: <PasswordRecovery />,
        errorElement: <ErrorPage />,
      },
    ];
  }

  return [
    {
      path: RoutePath.ROOT,
      id: 'root',
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: RoutePath.HOME,
          errorElement: <ContentError />,
          element: <Home />,
        },
        {
          path: RoutePath.MY_LISTS,
          errorElement: <ContentError />,
          element: <MyLists />,
        },
        {
          path: RoutePath.LIST,
          errorElement: <ContentError />,
          element: <List />,
        },
        {
          path: RoutePath.LEADS,
          errorElement: <ContentError />,
          element: <ComingSoonPage />,
        },
        {
          path: RoutePath.SEARCH,
          errorElement: <ContentError />,
          element: <Search />,
        },
        {
          path: RoutePath.COMPANY,
          errorElement: <ContentError />,
          element: <Company />,
        },
        {
          path: RoutePath.PROFILE,
          errorElement: <ContentError />,
          element: <Profile />,
        },
        ...SIDEBAR_MODULES.flatMap((block) => block.children),
      ],
    },
  ];
};
