import React, { useEffect, useState } from 'react';
import NoteCard from './NoteCard';
import { httpCreateCompanyNotes, httpDeleteCompanyNotes, httpUpdateCompanyNotes } from '../api/CompaniesAPI';

const Notes = ({ companyId, oldNotes, loadCompanyNotes }) => {
  const [notes, setNotes] = useState(oldNotes);
  const [previousNotes, setPreviousNotes] = useState(oldNotes);
  const [selectedId, setSelectedId] = useState(null);

  const AddNote = () => {
    setNotes([{ id: 0, title: '', content: '' }, ...notes]);
    setSelectedId(0);
  };

  const handleSaveNote = () => {
    notes.forEach((note) => {
      if (note.id === selectedId && note.id === 0) {
        httpCreateCompanyNotes(companyId, note.title, note.content).then((response) => {
          loadCompanyNotes();
          setSelectedId(null);
        });
      }
      if (note.id === selectedId && note.id !== 0) {
        httpUpdateCompanyNotes(companyId, note.id, note.title, note.content).then((response) => {
          loadCompanyNotes();
          setSelectedId(null);
        });
      }
    });
  };

  useEffect(() => {
    setNotes(oldNotes);
    setPreviousNotes(oldNotes);
  }, [oldNotes]);

  const resetNotes = () => {
    setNotes(previousNotes);
    setSelectedId(null);
  };

  const handleDeleteNote = (noteId) => {
    httpDeleteCompanyNotes(companyId, noteId)
      .then((response) => {
        loadCompanyNotes();
      })
      .catch((error) => {});
    setSelectedId(null);
  };

  const handleChangeNote = (e, id) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNotes(
      notes.map((n) => {
        if (n.id === id) {
          return { ...n, [name]: value };
        } else {
          return n;
        }
      }),
    );
  };

  return (
    <div className=" flex  flex-col justify-between gap-4">
      {/* Title and Add Button */}
      <div className="flex justify-between items-center ">
        <h2 className="text-lg font-semibold">Notes</h2>
        <button
          className="btn btn-primary btn-circle btn-sm"
          disabled={notes.filter((note) => note.id == 0).length > 0}
          onClick={AddNote}
        >
          <div className="text-xl font-thin">+</div>
        </button>
      </div>
      {/* New Note */}

      {/* Old Note */}
      {notes.length > 0 ? (
        notes?.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            resetNotes={resetNotes}
            handleChangeNote={handleChangeNote}
            handleDeleteNote={handleDeleteNote}
            handleSaveNote={handleSaveNote}
          />
        ))
      ) : (
        <div className="italic">No notes found</div>
      )}
    </div>
  );
};

export default Notes;
