import { LocalizedText, Typography } from '@components/Typography';

type TeamMetricSubtitleProps = { subtitle: LocalizedText; tooltip?: LocalizedText };

// TODO: Add tooltip
export function TeamMetricSubtitle({ subtitle, tooltip }: TeamMetricSubtitleProps) {
  return (
    <div className="flex gap-1">
      <Typography {...subtitle} variant="sm/regular" className="leading-[22px] text-grey-900 first-letter:capitalize" />
    </div>
  );
}
