import { Typography } from '@components/Typography';
import { FC, PropsWithChildren } from 'react';

type FormFieldProps = PropsWithChildren & {
  title: string;
};

export const FormField: FC<FormFieldProps> = ({ title, children }) => (
  <div className="flex w-full flex-col gap-2">
    <Typography arbitrary text={title} className="text-xs leading-[17px] text-black first-letter:capitalize" />
    {children}
  </div>
);
