import { api } from '@/api/apiV2';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

// TODO: Should be infered from openapi configuration. Our backend has a lack of configuration for swagger
export type UserResponse = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  profileImageUrl?: string;
  company?: string;
};

type UseUserInfoArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: UserResponse) => void;
};

export const useUserInfo = ({ signal, onSuccess }: UseUserInfoArgs) => {
  const { data, isFetching } = useQuery<UserResponse>({
    queryKey: [QueryKeys.UserInfo],
    queryFn: async () => {
      const response = await api.accounts.accountsList({
        signal,
      });

      const result = response.data as unknown as UserResponse;
      onSuccess?.(result);

      return result;
    },
    staleTime: Infinity,
  });

  return {
    userInfo: data,
    isUserInfoLoading: isFetching,
  };
};
