import { cn } from '@/lib/utils';
import { RouteModule } from '@/router/types';
import { Typography } from '@components/Typography';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

type SidebarItemProps = {
  item: RouteModule;
};

export const SidebarItem: FC<SidebarItemProps> = ({ item }) => (
  <li>
    <NavLink
      to={item.query ? `${item.path}?${item.query}` : (item.path as string)}
      className={({ isActive }) =>
        cn(
          'flex h-[34px] w-full cursor-pointer items-center gap-2 rounded-md px-2 sidebar-expanded:hover:bg-slate-200 sidebar-closed:p-1 motion-safe:transition-all motion-safe:duration-300 motion-reduce:transition-none',
          isActive ? 'pointer-events-none font-bold text-base-content *:font-bold' : 'opacity-50',
        )
      }
    >
      <item.icon
        size={22}
        className="shrink-0 sidebar-closed:delay-75 sidebar-closed:duration-200 sidebar-closed:hover:scale-125 motion-safe:transition-all motion-safe:duration-500 motion-reduce:transition-none"
      />
      <Typography
        text={item.title}
        variant="lg/regular"
        className="invisible h-0 shrink-0 capitalize leading-[34px] text-grey-900 opacity-0 sidebar-expanded:visible sidebar-expanded:h-[34px] sidebar-expanded:opacity-100 motion-safe:transition-all motion-safe:duration-[350ms] motion-reduce:transition-none"
      />
    </NavLink>
  </li>
);
