import { Typography } from '@components/Typography';
import { FC } from 'react';
import { RadioCard, SelectableRadioCardProps } from '../parts';
import { formatCreditCardDate } from './helpers';
import { CreditCardResponse } from './types';

type PaymentCardProps = {
  cardInfo: CreditCardResponse;
} & Omit<SelectableRadioCardProps, 'children' | 'labelProps' | 'inputProps' | 'id'>;

export const PaymentCard: FC<PaymentCardProps> = ({ cardInfo: { id, last4Digits, expirationDate, type }, ...rest }) => (
  <RadioCard
    {...rest}
    id={id}
    labelProps={{
      className: 'w-1/2 min-w-[287px]',
    }}
  >
    <div className="flex flex-col *:leading-[17px] *:text-black *:first-letter:capitalize">
      <Typography text={'cardEndingIn'} interpolationsParams={{ type, last4Digits }} variant="xs/bold" />
      <Typography
        text={'expireIn'}
        interpolationsParams={{ date: formatCreditCardDate(expirationDate) }}
        variant="xs/regular"
      />
    </div>
  </RadioCard>
);
