import { QueryKey, useQuery } from '@tanstack/react-query';
import { ColumnSort, SortingState } from '@tanstack/react-table';
import { TableDataResponse, TableElement, TableRequestBody } from './types';

type UseTableQueryArgs<T extends TableElement> = {
  queryKey: QueryKey;
  fetcher: (options: { params: TableRequestBody }) => Promise<TableDataResponse<T>>;
  sorting: ColumnSort;
  afterFetchHook: (result: TableDataResponse<T>) => void;
  offset: number;
  limit: number;
};

export const useTableQuery = <T extends TableElement>({
  queryKey,
  fetcher,
  sorting,
  offset,
  limit,
  afterFetchHook,
}: UseTableQueryArgs<T>) => {
  return useQuery({
    queryKey: [...queryKey, sorting, offset, limit],
    queryFn: async () => {
      const result = await fetcher({
        params: {
          sorting,
          offset,
          limit,
        },
      });

      afterFetchHook(result);

      return result;
    },
  });
};
