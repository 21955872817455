import { DEFAULT_LIMIT, MAX_LIMIT, MIN_LIMIT, MIN_OFFSET } from '@components/Pagination/constants';
import { companySizeOptions, industryOptions, latestFundingTypeOptions, regionOptions } from '../data/options';

export const convertDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleDateString('en-GB');
};

export const resetFormData = (searchParams) => {
  let foundedFrom = null;
  let foundedTo = null;

  if (searchParams.get('foundedFrom') === '1900') {
    if (searchParams.get('foundedFromChanged') === 'true') {
      foundedFrom = 1900;
    }
  } else {
    foundedFrom = searchParams.get('foundedFrom');
  }

  if (searchParams.get('foundedTo') === '2024') {
    if (searchParams.get('foundedToChanged') === 'true') {
      foundedTo = 2024;
    }
  } else {
    foundedTo = searchParams.get('foundedTo');
  }

  const initialLimit = searchParams.get('limit');

  return {
    query: searchParams.get('query') || '',
    type: searchParams.get('type') === 'true' || false,
    foundedFrom: foundedFrom || 1900,
    foundedTo: foundedTo || 2024,
    foundedFromChanged: searchParams.get('foundedFromChanged') === 'true' || false,
    foundedToChanged: searchParams.get('foundedToChanged') === 'true' || false,
    relevantScore: searchParams.get('relevantScore')?.split('-').map(Number) || [65, 100],
    latestFundingType: latestFundingTypeOptions[searchParams.get('latestFundingType')] || '',
    industry: industryOptions[searchParams.get('industry')] || '',
    companySize: searchParams.get('companySize')
      ? (searchParams.get('companySize') || '')
          .split(',')
          .filter((element) => element !== 'undefined')
          .map((element) => companySizeOptions[element])
      : [],
    selectedRegion: regionOptions.find((option) => option.value === searchParams.get('region')) || '',
    startup: searchParams.get('startup') === 'true' || false,
    funding: searchParams.get('funding') === 'true' || false,
    fundingStages: searchParams.get('fundingStages')
      ? (searchParams.get('fundingStages') || '')
          .split(',')
          .filter((element) => element !== 'undefined')
          .map((element) => latestFundingTypeOptions[element])
      : [],
    offset: Math.max(MIN_OFFSET, +(searchParams.get('offset') ?? MIN_OFFSET)),
    limit:
      initialLimit === null ? DEFAULT_LIMIT : Math.max(MIN_LIMIT, Math.min(+(initialLimit ?? MIN_LIMIT), MAX_LIMIT)),
  };
};

export const buildQueryParams = (formData) => {
  const queryParams = new URLSearchParams();
  queryParams.append('query', formData.query);
  queryParams.append('type', formData.type);

  if (formData.selectedRegion) queryParams.append('region', formData.selectedRegion.value);
  if (formData.latestFundingType) queryParams.append('latestFundingType', formData.latestFundingType.value);
  if (formData.industry) queryParams.append('industry', formData.industry.value);
  if (formData.startup) queryParams.append('startup', formData.startup);
  if (formData.funding) queryParams.append('funding', formData.funding);

  const companySizeValues = formData.companySize?.map((item) => item.value);
  if (companySizeValues.length > 0) queryParams.append('companySize', companySizeValues.join(','));

  const fundingStagesValues = formData.fundingStages?.map((item) => item.value);
  if (fundingStagesValues.length > 0) queryParams.append('fundingStages', fundingStagesValues.join(','));

  queryParams.append('relevantScore', formData.relevantScore.join('-'));
  // queryParams.append('foundingDate', formData.foundingDate.join('-'));

  if (formData.foundedFrom === 1900) {
    if (formData.foundedFromChanged === true) {
      queryParams.append('foundedFromChanged', formData.foundedFromChanged);
      queryParams.append('foundedFrom', formData.foundedFrom);
    }
  } else {
    queryParams.append('foundedFrom', formData.foundedFrom);
  }

  if (formData.foundedTo === 2024) {
    if (formData.foundedToChanged === true) {
      queryParams.append('foundedToChanged', formData.foundedToChanged);
      queryParams.append('foundedTo', formData.foundedTo);
    }
  } else {
    queryParams.append('foundedTo', formData.foundedTo);
  }

  if (formData.offset !== undefined) queryParams.append('offset', formData.offset);
  if (formData.limit) queryParams.append('limit', formData.limit);

  return queryParams;
};
