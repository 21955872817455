export enum RoutePath {
  ROOT = '/',
  HOME = '/home',
  MY_LISTS = '/my-lists',
  LIST = '/my-lists/:list_id',
  LEADS = '/leads',
  INVESTORS = '/investors',
  SIGNALS = '/signals',
  SEARCH = '/search',
  COMPANY = '/company/:id',
  MY_AREA = '/my-area',
  MY_COMPANIES = '/my-companies',
  PROFILE = '/profile',
  LOGIN = '/login',
  SIGN_UP = '/signup',
  PASSWORD_RECOVERY = '/password-recovery',
  ERROR = '*',
}
