import { FC } from 'react';
import { TeamMetric } from '../parts';
import { TechnicalFiguresMetricModalContent } from './parts';

export const TechnicalFiguresMetric: FC = () => {
  return (
    <TeamMetric icon="Robot" modalContent={<TechnicalFiguresMetricModalContent />} disabled title="Coming soon">
      {/* <TeamMetric.Title text="nPercentage" interpolationsParams={{ count: 22 }} /> */}
      <TeamMetric.Title arbitrary text="n/d" className="first-letter:normal-case" />
      <TeamMetric.Subtitle
        subtitle={{
          text: 'technicalFigure_other',
        }}
      />
    </TeamMetric>
  );
};
