import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { httpResetPassword } from '../api/AccountsAPI';

function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswordRecovery = async () => {
    try {
      setLoading(true);

      await httpResetPassword(email);
      setMessage('Password reset email sent. Please check your inbox.');
      setLoading(false);
    } catch (error) {
      setLoading(false);

      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex h-[100vh] w-full flex-wrap items-start justify-center font-primary">
      <div className="flex h-[100%] w-[80%] w-full flex-col items-center justify-evenly bg-white py-10 lg:w-[50%]">
        <div className="flex w-full flex-col items-start justify-items-start p-10">
          <img className="w-[160px]" src="/logo.png" alt="logo" />
        </div>
        <form
          className="mx-auto flex h-[100%] w-full flex-col items-center justify-start space-y-10 bg-white p-10"
          onSubmit={(e) => {
            e.preventDefault();
            handlePasswordRecovery();
          }}
        >
          <h1 className="w-full text-start font-primary text-3xl text-slate-700 lg:w-[100%] lg:text-4xl">
            Recover your password
          </h1>
          <p className="w-full text-start text-lg text-slate-500 lg:w-[100%] lg:text-xl">
            Enter your email address to receive a password reset link
          </p>
          <input
            type="email"
            placeholder="Email"
            className="text-slate-1000 mt-2 w-full rounded-md border border-slate-400 bg-white p-3 lg:w-[100%]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="w-full rounded-lg border-none bg-primary-600 p-2 text-lg font-semibold text-primary-content duration-200 hover:brightness-[1.1] lg:w-[100%]"
          >
            {!loading ? 'Send password reset Email' : <ClipLoader color="white" size="25" />}
          </button>
          {message && (
            <p
              className={`w-full text-center text-lg lg:w-[100%] lg:text-xl ${
                message.includes('Error') ? 'text-red-500' : 'text-green-500'
              }`}
            >
              {message}
            </p>
          )}
        </form>
      </div>
      <div className="hidden h-[100%] w-[50%] lg:block">
        <img src="/logindesign.png" className="h-[100%] w-full object-cover" alt="login design" />
      </div>
    </div>
  );
}

export default PasswordRecovery;
