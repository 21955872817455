import { InitialTableState } from '@components/Tables/types';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// TODO: Infer it from openapi
export enum PaymentStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Paid = 'Paid',
}

// TODO: Infer it from openapi
export type PaymentHistoryResponse = {
  id: string;
  date: string;
  description: string;
  amount: number;
  status: PaymentStatus;
};

const columnHelper = createColumnHelper<PaymentHistoryResponse>();

export const usePaymentHistoryTableInitialState = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('date', {
          id: 'date',
          cell: (info) => info.getValue(),
          header: 'date',
          minSize: 100,
          maxSize: 200,
          meta: {
            className: () => 'font-bold',
          },
        }),
        columnHelper.accessor('description', {
          id: 'description',
          cell: (info) => info.getValue(),
          header: t('description', { punctuation: ' ' }),
          minSize: 100,
          maxSize: 200,
          meta: {
            className: () => 'font-bold',
          },
        }),
        columnHelper.accessor('amount', {
          id: 'amount',
          cell: (info) => info.getValue(),
          header: 'amount',
          minSize: 100,
          maxSize: 200,
          meta: {
            className: () => 'font-bold',
          },
        }),
        columnHelper.accessor('status', {
          id: 'status',
          cell: (info) => info.getValue(),
          header: 'status',
          minSize: 100,
          maxSize: 200,
          meta: {
            className: () => 'font-bold',
          },
        }),
      ] as ColumnDef<PaymentHistoryResponse>[],
    [],
  );

  return useMemo(
    () =>
      ({
        sorting: [{ id: 'date', desc: true }],
        columns,
      }) as InitialTableState<PaymentHistoryResponse>,
    [columns],
  );
};
