import { cn } from '@/lib/utils';
import { BaseModal } from '@components/BaseModal/BaseModal';
import { IconName, SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { makeAccessible } from '@utils';
import { ComponentProps, ReactNode, useState } from 'react';
import { TeamMetricSubtitle } from './TeamMetricSubtitle';
import { TeamMetricTitle } from './TeamMetricTitle';

type TeamMetricProps = {
  icon: IconName;
  disabled?: boolean;
  className?: string;
  modalContent?: ReactNode;
} & Omit<ComponentProps<'article'>, 'aria-disabled' | 'role' | 'tabIndex' | 'onClick' | 'onKeyDown'>;

export function TeamMetric({ icon, disabled = false, className, modalContent, children, ...rest }: TeamMetricProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleModal = () => setIsOpen((prev) => !prev);

  return (
    <>
      <article
        {...rest}
        data-clickable={!!modalContent}
        aria-disabled={disabled}
        {...(modalContent && !disabled ? makeAccessible(handleToggleModal) : null)}
        className={cn(
          'group flex w-fit flex-col gap-[1px] rounded-lg border-0 px-2 aria-disabled:cursor-not-allowed aria-disabled:opacity-50 hover:data-[clickable=true]:aria-[disabled=false]:bg-grey-100 motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none',
          className,
        )}
      >
        <SvgIcon name={icon} className="size-7" />
        {children}
        <div className="flex items-center group-data-[clickable=false]:hidden">
          <Typography
            text={'more'}
            variant="xs/semibold"
            className="bg-gradient-to-r from-gradient-from to-gradient-to bg-clip-text text-transparent first-letter:capitalize"
          />
          <SvgIcon name="ChevronRightGradient" />
        </div>
      </article>
      {isOpen && modalContent ? (
        <BaseModal isOpen={isOpen} onCloseHandler={handleToggleModal}>
          {modalContent}
        </BaseModal>
      ) : null}
    </>
  );
}

TeamMetric.Title = TeamMetricTitle;
TeamMetric.Subtitle = TeamMetricSubtitle;
