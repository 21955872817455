import { SignalResponse } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { SignalTypesData } from '@/pages/Signals/constants';
import { getMatchColor } from '@/pages/Signals/helpers';
import { RoutePath } from '@/router/constants';
import { Typography } from '@components/Typography';
import { InfoCard } from '@global/components/InfoCard';
import { FC } from 'react';

type SignalCardBodyProps = {
  signal: SignalResponse;
};

export const SignalCardBody: FC<SignalCardBodyProps> = ({ signal }) => {
  const data = SignalTypesData.get(signal.signalType);

  return (
    <InfoCard.Body className="gap-3">
      <InfoCard.Header
        image={signal.company.logoUrl}
        title={signal.company.companyName ?? '-'}
        link={RoutePath.COMPANY.replace(':id', signal.company.companyId)}
      >
        <Typography
          text={'matchNPercentages'}
          interpolationsParams={{ percentage: (signal.score * 100).toFixed(1) }}
          variant="xs/regular"
          className={cn('truncate leading-[19px] first-letter:capitalize', getMatchColor(signal.score))}
        />
      </InfoCard.Header>
      <InfoCard.Description>
        <InfoCard.DescriptionItem text="foundedAt" interpolationsParams={{ year: signal.company.foundedIn ?? '-' }} />
        <InfoCard.DescriptionItem
          text="hq"
          interpolationsParams={{ country: signal.company.headquarters?.additional ?? '-' }}
        />
        {signal.company.foundingInfo?.lastRound?.type && signal.company.foundingInfo.lastRound?.amountRaised ? (
          <InfoCard.DescriptionItem
            arbitrary
            text={`${signal.company.foundingInfo.lastRound?.type}: ${signal.company.foundingInfo.lastRound?.amountRaised}`}
            className="shrink-0"
          />
        ) : null}
      </InfoCard.Description>
      <InfoCard.Content>
        <InfoCard.ContentItem
          title={{
            text: data?.trigger ?? 'query',
            interpolationsParams: { punctuation: ':' },
          }}
        >
          <InfoCard.ContentItemContent text={signal.body} />
        </InfoCard.ContentItem>
      </InfoCard.Content>
    </InfoCard.Body>
  );
};
