import { Checkbox } from '@components/Checkbox';
import { getURLBooleanFilter, setURLBooleanFilter } from '@global/filters';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ShowDuplicatesFilter: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = getURLBooleanFilter({
    field: 'dontShowDuplicates',
    searchParams,
  });

  const onChange = () => {
    setURLBooleanFilter({
      field: 'dontShowDuplicates',
      searchParams,
      value: value === null ? false : !value,
    });

    setSearchParams(searchParams, {
      replace: true,
    });
  };

  return (
    <Checkbox
      checked={value ?? true}
      onChange={onChange}
      label={{
        text: 'dontShowDuplicates',
      }}
    />
  );
};
