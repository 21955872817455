import type { Option } from '@global/types';

export const enumToSelectOptions = (ob: { [key in string]: key }) =>
  Object.values(ob).map(
    (value) =>
      ({
        label: value,
        value,
      }) satisfies Option<string>,
  );
