import { api } from '@/api/apiV2';
import { UpdateQuerySignalTypesRequest } from '@/api/generated/Api';
import { useMutation } from '@tanstack/react-query';

type UseUpdateSignalTypesArgs = {
  queryId: string;
  signal?: AbortSignal;
  onSuccess?: (data: UpdateQuerySignalTypesRequest) => void;
};

export const useUpdateSignalTypes = ({ queryId, signal, onSuccess }: UseUpdateSignalTypesArgs) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['update-signal-types'],
    mutationFn: async (data: UpdateQuerySignalTypesRequest) => {
      await api.query.updateQuerySignalTypes(queryId, data, { signal });

      onSuccess?.(data);
    },
  });

  return {
    updateSignalTypes: mutate,
    isSignalTypesUpdating: isPending,
  };
};
