import { cn } from '@/lib/utils';
import { Button } from '@components/Button';
import type { FC } from 'react';
import { TabsProps } from './types';
import { useTabs } from './useTabs';

export const SubTabs: FC<TabsProps> = ({
  items,
  onSelectTab,
  className,
  navClassName,
  title,
  onKeyDown,
  currentTab,
  disabled,
}) => {
  const { tabListId, handleKeyDown, openTab, selectTab } = useTabs({
    items,
    onSelectTab,
    onKeyDown,
    currentTab,
    disabled,
  });

  return (
    <section className={cn('flex h-full w-full flex-col', className)}>
      {title ? (
        <span id={`tablist-${tabListId}`} className="sr-only">
          {title}
        </span>
      ) : null}
      <nav
        aria-labelledby={title ? `tablist-${tabListId}` : undefined}
        role="tablist"
        className={cn('mb-[23px] flex w-full max-w-full flex-wrap gap-2', navClassName)}
      >
        {items.map((item, index) => (
          <Button
            key={index}
            onKeyDown={handleKeyDown}
            id={`tab-${tabListId}-${index}`}
            type="button"
            role="tab"
            aria-selected={openTab === index}
            aria-controls={`tabpanel-${index}-${tabListId}`}
            tabIndex={openTab === index ? undefined : -1}
            disabled={disabled}
            onClick={() => selectTab(index)}
            text={item.title}
            size={'large'}
            intent={openTab === index ? 'accent' : 'empty'}
            className="px-[18px] py-1.5 text-lg capitalize leading-[22px]"
          />
        ))}
      </nav>
      <div
        aria-labelledby={`tab-${tabListId}-${items.findIndex((item) => item.title === items[openTab].title)}`}
        role="tabpanel"
        id="tab-content"
        className="flex h-full flex-col"
      >
        {items[openTab].element}
      </div>
    </section>
  );
};
