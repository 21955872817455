if (import.meta.env.VITE_ENV === 'prod') {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', 'mihz1sy3su');

  (function (d, w, c) {
    var gtagScript = d.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-ETC2JE7L02';
    d.head.appendChild(gtagScript);

    w.dataLayer = w.dataLayer || [];
    function gtag() {
      w.dataLayer.push(arguments);
    }
    w.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'G-ETC2JE7L02');
  })(document, window);
}
if (import.meta.env.VITE_ENV === 'noprod') {
  (function (d, w, c) {
    var gtagScript = d.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-8RQR44BQV9';
    d.head.appendChild(gtagScript);

    w.dataLayer = w.dataLayer || [];
    function gtag() {
      w.dataLayer.push(arguments);
    }
    w.gtag = gtag;
    gtag('js', new Date());
    gtag('config', '');
  })(document, window);
}
