import { NavLink } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { httpSignup, httpGoogleAuth } from '../api/AccountsAPI';
import { auth, googleProvider } from '../lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState(''); // [1
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName || !lastName || !email || !password || !cpassword) {
      setError('All fields are required');
      return false;
    } else if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return false;
    } else if (password.length < 6) {
      setError('Password should be at least 6 characters long');
      return false;
    } else if (password !== cpassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      httpGoogleAuth(user.accessToken, user.refreshToken).then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('refresh-token', response.data.refreshToken);
        localStorage.setItem('email', response.data.email);

        navigate('/home');
      });
    }
  });

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      window.location.href = '/home';
    }
  }, []);

  const handleSignup = () => {
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    httpSignup(email, password)
      .then((response) => {
        setSuccess(true);
        setError(false);
        setEmail('');
        setPassword('');
        setLoading(false);

        window.location.href = '/login';
      })
      .catch((error) => {
        setError('Invalid email or password');
        setLoading(false);
      });
  };

  return (
    <div className="min-h-screen w-full flex justify-center items-stretch flex-wrap bg-white">
      <div className="flex flex-col w-full bg-white justify-evenly items-center h-full w-[80%] lg:w-[50%] py-10">
        <div className="flex flex-col justify-items-start items-start lg:w-[80%] pb-10 ">
          <img className="w-[160px]" src="/logo.png" alt="logo" />
        </div>
        <form
          className="flex gap-6 justify-center items-center flex-col bg-white pt-10 w-full px-10 lg:px-0"
          onSubmit={(e) => {
            e.preventDefault();
            handleSignup();
          }}
        >
          {/* LOG IN */}
          <h1 className="text-slate-700 text-2xl lg:text-4xl text-start w-full lg:w-[80%] ">SIGN UP</h1>

          {/* FORM */}
          <div className="flex flex-col sm:flex-row justify-center items-center w-full lg:w-[80%] gap-6">
            <div className="flex flex-col justify-center items-center w-full lg:w-1/2 ">
              <input
                className="border mt-2 text-slate-900 bg-white border-slate-400 p-3 w-full rounded-md"
                id="firstName"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex flex-col justify-center items-center w-full lg:w-1/2">
              <input
                className="border mt-2 text-slate-900 bg-white border-slate-400 p-3  w-full rounded-md"
                id="lastName"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-full">
            <input
              className="border mt-2 text-slate-900 bg-white border-slate-400 p-3 w-full lg:w-[80%] rounded-md"
              id="email"
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full ">
            <input
              className="border mt-2 text-slate-900 bg-white border-slate-400 p-3 w-full lg:w-[80%] rounded-md"
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full ">
            <input
              className="border mt-2 text-slate-900 bg-white border-slate-400 p-3 w-full lg:w-[80%] rounded-md"
              type="password"
              id="password"
              placeholder="Confirm Password"
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
            />
          </div>
          {/* ERRORS */}
          {error && <p className="text-error text-left lg:w-[80%]  pl-3 text-sm"> * {error}</p>}
          {success && (
            <p className="text-green-600 text-sm text-left lg:w-[80%] border-l border-green-500 pl-3">
              Logged In Successfully
            </p>
          )}
          <button
            type="submit"
            className="text-primary-content duration-200 border-none text-lg bg-primary-600  hover:brightness-[1.1] p-2 w-full lg:w-[80%] font-semibold rounded-lg"
          >
            {!loading ? 'Sign In' : <ClipLoader color="white" size="25" />}
          </button>

          <div className="divider w-[80%] mx-auto my-0">OR</div>
          <div
            onClick={signInWithGoogle}
            className="border cursor-pointer flex justify-center items-center hover:bg-gray-100 duration-200 border-slate-400 text-lg text-black p-3 w-full lg:w-[80%] font-semibold rounded-md"
          >
            <FaGoogle />
            <span className="pl-2">Sign in with Google</span>
          </div>

          <div className="divider w-[80%] mx-auto my-0"></div>
          <p className="flex justify-center items-center w-full text-md lg:text-xl text-slate-700">
            Have an account Already?
          </p>
          <NavLink
            to="/login"
            className="bg-gray-700 text-primary-content text-center hover: duration-200 border-none text-lg p-2 w-full lg:w-[80%] font-semibold rounded-lg"
          >
            Log In
          </NavLink>
        </form>
      </div>
      {/* LOGO */}

      {/* Right side Image */}
      <div className="w-full lg:w-[50%] lg:flex hidden flex-grow">
        <img src="/logindesign.png" className="w-full object-cover" alt="login design" />
      </div>
    </div>
  );
}

export default Signup;
