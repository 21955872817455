export const latestFundingTypeOptions = [
  { value: 0, label: 'Angel' },
  { value: 1, label: 'Seed' },
  { value: 2, label: 'Series Unknown' },
  { value: 3, label: 'Pre Seed' },
  { value: 4, label: 'Series A' },
  { value: 5, label: 'Series B' },
  { value: 6, label: 'Series C' },
  { value: 7, label: 'Series D' },
  { value: 8, label: 'Series E' },
  { value: 9, label: 'Series F' },
  { value: 10, label: 'Series G' },
];

export const regionOptions = [
  { value: 'All', label: 'All' },
  { value: 'Germany', label: 'Germany' },
  { value: 'France', label: 'France' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Spain', label: 'Spain' },
  { value: 'United-kingdom', label: 'United Kingdom' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Finland', label: 'Finland' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Czech-republic', label: 'Czech Republic' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Romania', label: 'Romania' },
];

export const industryOptions = [
  { value: 0, label: 'Accounting' },
  { value: 1, label: 'Advertising Services' },
  { value: 2, label: 'Appliances, Electrical, and Electronics Manufacturing' },
  { value: 3, label: 'Computers and Electronics Manufacturing' },
  { value: 4, label: 'Entertainment Providers' },
  { value: 5, label: 'Environmental Services' },
  { value: 6, label: 'Financial Services' },
  { value: 7, label: 'Freight and Package Transportation' },
  { value: 8, label: 'Information Services' },
  { value: 9, label: 'Information Technology & Services' },
  { value: 10, label: 'Insurance' },
  { value: 11, label: 'International Affairs' },
  { value: 12, label: 'IT Services and IT Consulting' },
  { value: 13, label: 'Medical Device' },
  { value: 14, label: 'Medical Equipment Manufacturing' },
  { value: 15, label: 'Non-profit Organization Management' },
  { value: 16, label: 'Packaging and Containers Manufacturing' },
  { value: 17, label: 'Personal Care Product Manufacturing' },
  { value: 18, label: 'Photography' },
  { value: 19, label: 'Plastics Manufacturing' },
  { value: 20, label: 'Renewable Energy Equipment Manufacturing' },
  { value: 21, label: 'Renewable Energy Semiconductor Manufacturing' },
  { value: 22, label: 'Retail' },
  { value: 23, label: 'Retail Apparel and Fashion' },
  { value: 24, label: 'Settore alberghiero' },
  { value: 25, label: 'Software Development' },
  { value: 26, label: 'Technology, Information and Internet' },
  { value: 27, label: 'Travel Arrangements' },
  { value: 28, label: 'Truck Transportation' },
  { value: 29, label: 'Utilities' },
  { value: 30, label: 'Venture Capital and Private Equity Principals' },
  { value: 31, label: 'Wellness and Fitness Services' },
];

export const companySizeOptions = [
  { value: 0, label: '1 employee' },
  { value: 1, label: '2-10 employees' },
  { value: 2, label: '11-50 employees' },
  { value: 3, label: '51-200 employees' },
  { value: 4, label: '201-500 employees' },
  { value: 5, label: '501-1,000 employees' },
  { value: 6, label: '1,001-5,000 employees' },
  { value: 7, label: '5,001-10,000 employees' },
  { value: 8, label: '10,001+ employees' },
];
