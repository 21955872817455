import axios from 'axios';
import { signOut } from 'firebase/auth';
import { auth } from '../lib/firebase';

export const createCustomAxios = () =>
  axios.create({
    baseURL: import.meta.env.VITE_API_ENDPOINT,
    timeout: 60000,
  });

export const customAxios = createCustomAxios();

customAxios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const errorResponse = error.response;

    if (isNotFoundError(errorResponse)) {
      return errorResponse;
    }

    if (isUnauthorizedError(errorResponse)) {
      // localStorage.removeItem('token');
      // localStorage.removeItem('userId');
      // window.location.replace('/login');
      // return Promise.reject(error);
      return resetTokenAndReattemptRequest(error);
    }

    if (errorResponse === undefined) {
      alert('Could Not Connect To Server'); // Using alert as a placeholder
      return Promise.reject(error);
    }

    if (isServerError(errorResponse)) {
      alert('Server Error'); // Using alert as a placeholder
      return Promise.reject(error);
    }
  },
);

function isTokenExpiredError(errorResponse) {
  return errorResponse && errorResponse.status === 410;
}

function isServerError(errorResponse) {
  return errorResponse && errorResponse.status === 500;
}

function isUnauthorizedError(errorResponse) {
  return errorResponse && errorResponse.status === 401;
}

function isNotFoundError(errorResponse) {
  return errorResponse && errorResponse.status === 404;
}

function isSuccess(errorResponse) {
  return errorResponse && errorResponse.status === 200;
}

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

export async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });

    isAlreadyFetchingAccessToken = true;
    const refreshTokenUrl = `${import.meta.env.VITE_API_ENDPOINT}/api/accounts/refresh-token`;

    try {
      const response = await axios({
        method: 'post',
        url: refreshTokenUrl,
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
        data: {
          refreshToken: localStorage.getItem('refresh-token'),
        },
      });

      if (isSuccess(response)) {
        await saveAccessToken(response.data); // save the newly refreshed token
        onAccessTokenFetched(response.data.idToken);
        isAlreadyFetchingAccessToken = false;
        return await retryOriginalRequest;
      } else {
        isAlreadyFetchingAccessToken = false;
        localStorage.removeItem('token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        await signOut(auth);
        window.location.replace('/login');
      }
    } catch (err) {
      if (err.code === 'ERR_BAD_REQUEST') {
        isAlreadyFetchingAccessToken = false;

        localStorage.removeItem('token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        await signOut(auth);
        window.location.replace('/login');
      }
      return Promise.reject(err);
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token) {
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export const getAccessToken = () => {
  return localStorage.getItem('token');
};

function saveAccessToken(data) {
  localStorage.setItem('refresh-token', data.refreshToken);
  localStorage.setItem('token', data.idToken);
}
