import { cn } from '@/lib/utils';
import { ComponentPropsWithoutRef, ElementType, PropsWithChildren } from 'react';
import { Loadable } from '../Loadable';

type NegativeLayoutProps<T extends ElementType> = {
  as?: T;
  isLoading?: boolean;
  className?: string;
} & ComponentPropsWithoutRef<T> &
  PropsWithChildren;

export const NegativeLayout = <T extends ElementType>({
  as,
  isLoading,
  className,
  children,
  ...rest
}: NegativeLayoutProps<T>) => {
  const Component = as || 'article';

  return (
    <Component
      {...rest}
      className={cn(
        'bg-black-500/15 flex size-full items-center justify-center rounded-[1.25rem] border-0 p-7',
        className,
      )}
    >
      <Loadable isLoading={isLoading}>{children}</Loadable>
    </Component>
  );
};
