import { cn } from '@/lib/utils';
import { Avatar } from '@components/Avatar';
import { Typography } from '@components/Typography';
import { PropsWithChildren } from 'react';
import { InfoCardTitle } from './parts';

type InfoCardHeaderProps = {
  image: string | null | undefined;
  title: string;
  subtitle?: string;
  link?: string;
  className?: string;
} & PropsWithChildren;

export function InfoCardHeader({ image, title, subtitle, link, className, children }: InfoCardHeaderProps) {
  return (
    <div className="flex gap-[15px]">
      <Avatar src={image} className="size-[60px]" />
      <span className="h-full w-[1px] bg-primary-600" />
      <div className={cn('flex flex-col justify-between gap-0.5 overflow-hidden', className)}>
        <InfoCardTitle title={title} link={link} />
        {subtitle ? (
          <Typography
            arbitrary
            text={subtitle}
            variant="xs/regular"
            className="truncate leading-[14px] text-grey-700 first-letter:capitalize"
          />
        ) : null}
        {children}
      </div>
    </div>
  );
}
