import { PAGINATION_LIMIT_OPTIONS } from '@components/Pagination';
import { SvgIcon } from '@components/SvgIcon';
import { FC } from 'react';
import Select from 'react-select';
import type { Option } from './types';

type LimitSelectorProps = {
  limit: number;
  setLimit: (limit: number) => void;
};

export const LimitSelector: FC<LimitSelectorProps> = ({ limit, setLimit }) => {
  return (
    <Select
      //@ts-expect-error types
      options={PAGINATION_LIMIT_OPTIONS}
      name="limit"
      value={limit}
      isSearchable={false}
      placeholder={limit}
      menuPlacement="top"
      isOptionDisabled={(option) => (option as unknown as Option).value === +limit}
      className="rounded-lg bg-base-200 text-grey-900 focus:outline-none"
      components={{
        DropdownIndicator: () => <SvgIcon name={'ChevronDown'} className="px-[9px] py-[10.5px]" />,
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          fontSize: '10px',
          border: 'none',
          height: '36px',
          paddingInline: '12px',
          cursor: 'pointer',
        }),
        menuList: (base) => ({
          ...base,
          fontSize: '10px',
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0px',
        }),
        placeholder: (base) => ({
          ...base,
          margin: '0px',
          fontSize: '12px',
        }),
      }}
      onChange={(value) => setLimit((value as unknown as Option).value)}
      menuPortalTarget={document.body}
    />
  );
};
