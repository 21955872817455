import { CreditCardResponse, CreditCardType } from '@global/components/RadioCards/PaymentCard/types';

export const TEST_PAYMENT_METHODS: CreditCardResponse[] = [
  {
		id: '1',
    type: CreditCardType.MasterCard,
    last4Digits: 3221,
    expirationDate: '2026-12-07T00:00:00Z',
  },
	{
		id: '2',
    type: CreditCardType.Visa,
    last4Digits: 1234,
    expirationDate: '2025-01-07T00:00:00Z',
  },
];
