import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

export const MultiSelect = ({ options, selectedItems, handleToggleItem, inputClassName, optionLabelClassName }) => {
  const { t } = useTranslation();

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={twMerge(
            'border-1 select bordered select-sm h-10 w-full items-center border-base-300 bg-base-200 text-xs',
            inputClassName,
          )}
        >
          {renderText(selectedItems, t)}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="z-[10005] max-h-[160px] w-[200px] overflow-y-auto rounded-lg bg-base-200 px-4 text-xs shadow-md"
          sideOffset={10}
          align="start"
        >
          {options.map((item) => (
            <div key={item.label} className="my-2 flex items-center">
              <input
                type="checkbox"
                checked={selectedItems.findIndex((option) => option.value === item.value) !== -1}
                onChange={() => handleToggleItem(item)}
                className="checkbox size-[17px] rounded-md"
              />
              <label className={twMerge('ml-2', optionLabelClassName)}>{t(item.label)}</label>
            </div>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const renderText = (items, t) => {
  if (items.length === 0) {
    return <span className="opacity-70">Select...</span>;
  } else if (items.length === 1) {
    return t(items[0].label);
  } else {
    return `${items.length} selected`;
  }
};
