import { SignalsGroupResponse } from '@/api/generated/Api';
import { Typography } from '@components/Typography';
import { FC } from 'react';
import { SignalCardComponents } from '../../constants';
import { formatDate } from './helpers';

type SignalsGroupProps = {
  group: SignalsGroupResponse;
};

export const SignalsGroup: FC<SignalsGroupProps> = ({ group }) => (
  <div className="@container flex flex-col gap-3">
    <Typography arbitrary text={formatDate(group.date)} variant="xs/bold" className="text-grey" />
    <div className="@[800px]:grid-cols-2 @[1150px]:grid-cols-3 @[1550px]:grid-cols-4 grid grid-cols-1 gap-5">
      {group.signals.map((signal) => SignalCardComponents[signal.signalType]({ signal }, signal.id))}
    </div>
  </div>
);
