import { cn } from '@/lib/utils';
import { LocalizedText, Typography } from '@components/Typography';
import { FC, PropsWithChildren } from 'react';

type ModalFormFieldProps = {
  title: LocalizedText;
  className?: string;
  required?: boolean;
} & PropsWithChildren;

export const ModalFormField: FC<ModalFormFieldProps> = ({ title, required, className, children }) => (
  <div className="flex flex-col gap-2">
    <Typography
      {...title}
      variant="xs/bold"
      className={cn(
        'capitalize leading-[17px] text-grey-700',
        className,
        required ? "after:ps-0.5 after:text-red-500 after:content-['*']" : undefined,
      )}
    />
    {children}
  </div>
);
