import { GlobalLoadingFallback } from '@global/components/Layout/GlobalLoadingFallback';
import { queryClient } from '@global/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, Suspense } from 'react';
import App from './App';
import { ListProvider } from './contexts/ListContext';

export const Root: FC = () => {
  return (
    <Suspense fallback={<GlobalLoadingFallback />}>
      <QueryClientProvider client={queryClient}>
        <ListProvider>
          <App />
        </ListProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Suspense>
  );
};
