import { Typography } from '@components/Typography';
import { NavLink } from 'react-router-dom';

export const ErrorPage = () => (
  <div className="flex h-dvh w-screen flex-col items-center justify-center gap-4">
    <Typography text={'oopsSomethingWentWrong'} tag="h2" className="text-primary text-3xl font-bold" />
    <Typography text={'weAreSorry'} className="text-primary" />
    <NavLink to="/home" className="focus:shadow-outline rounded px-4 py-2 hover:brightness-[1.1] focus:outline-none">
      <Typography text={'visitHome'} variant="base/semibold" className="text-bg-primary-600 capitalize" />
    </NavLink>
  </div>
);
