import { cn } from '@/lib/utils';
import { SvgIcon } from '@components/SvgIcon';
import { LocalizedText, Typography } from '@components/Typography';
import { ChangeEvent, ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

export type RadioCardProps = {
  id: string;
  labelProps?: Omit<ComponentPropsWithoutRef<'label'>, 'htmlFor' | 'aria-selected' | 'children'>;
  label: LocalizedText;
  checked: boolean | null;
  onChange: (id: string, e: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: Omit<ComponentPropsWithoutRef<'input'>, 'id' | 'type' | 'checked' | 'onChange'>;
} & PropsWithChildren;

export const RadioButton: FC<RadioCardProps> = ({ id, labelProps, label, checked, onChange, inputProps }) => (
  <label
    {...labelProps}
    htmlFor={id}
    className={cn('group/radio flex cursor-pointer items-center gap-1', labelProps?.className)}
  >
    <Typography {...label} className="text-xs capitalize text-grey-900" />
    <div className="relative flex size-[18px] rounded-full bg-gradient-to-r from-gradient-from to-gradient-to p-[1px] has-[:checked]:p-[9px] group-hover/radio:p-[9px] motion-safe:transition-all motion-safe:duration-300 motion-reduce:transition-none">
      <input
        id={id}
        type="checkbox"
        checked={checked === true}
        onChange={(e) => onChange(id, e)}
        className={cn('peer size-full appearance-none rounded-full bg-white', inputProps?.className)}
      />
      <SvgIcon
        name="Checkmark"
        className="motion-safe:peer-checked:animate-check absolute left-0 top-0 opacity-0 motion-safe:translate-y-full motion-safe:transition-all motion-safe:duration-200 motion-reduce:peer-checked:opacity-100"
      />
    </div>
  </label>
);
