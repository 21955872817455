import { SignalType } from '@/api/generated/Api';
import { ReactNode } from 'react';
import { NewCompanySignalCard, SignalCardProps, UnknownSignalCard } from './parts';
import { SignalTypeData } from './types';

export const SignalCardComponents: Record<SignalType, (props: SignalCardProps, key: string) => ReactNode> = {
  [SignalType.Unknown]: (props: SignalCardProps, key: string) => <UnknownSignalCard key={key} {...props} />,
  [SignalType.NewCompanies]: (props: SignalCardProps, key: string) => <NewCompanySignalCard key={key} {...props} />,
};

export const SignalTypesData = new Map<SignalType, SignalTypeData>([
  [
    SignalType.Unknown,
    {
      tagBackgroundColor: 'red-500',
      tagTextColor: 'black',
      trigger: 'query',
    },
  ],
  [
    SignalType.NewCompanies,
    {
      tagBackgroundColor: 'primary-200',
      tagTextColor: 'base-50',
      trigger: 'query',
    },
  ],
]);
