import { Tabs } from '@components/Tabs';
import { ComponentProps } from 'react';
import { Navigate } from 'react-router-dom';
import { LinkTabItem } from './types';
import { useLinkTabs } from './useLinkTabs';

type LinkTabsProps = Omit<ComponentProps<typeof Tabs>, 'items'> & {
  items: LinkTabItem[];
  stepName?: string;
};

/**
 * HOC over Tabs component to add Link support and relate Tabs to the URL.
 */
export const LinkTabs = ({ stepName = 'tab', ...props }: LinkTabsProps) => {
  const { tab, currentTab, handleSelectTab } = useLinkTabs({
    items: props.items,
    stepName,
    initialTab: props.currentTab,
  });

  if (!props.items.some((item) => item.title === tab || tab !== '')) return <Navigate to="/not-found" />;

  return (
    <Tabs
      {...props}
      onSelectTab={(tab: LinkTabItem) => handleSelectTab(tab.path || tab.title)}
      currentTab={currentTab}
    />
  );
};
