import { cn } from '@/lib/utils';
import { FC, PropsWithChildren } from 'react';

type TileLayoutProps = PropsWithChildren & {
  className?: string;
};

export const TileLayout: FC<TileLayoutProps> = ({ className, children }) => {
  return (
    <article className={cn('card-elevation flex w-full flex-col justify-start bg-center p-4 gap-4', className)}>
      {children}
    </article>
  );
};
