import { cn } from '@/lib/utils';
import { LocalizedText, Typography } from '@components/Typography';
import { ArbitraryText } from '@components/Typography/types';

type TeamMetricTitleProps = (LocalizedText | ArbitraryText) & { className?: string };

export function TeamMetricTitle({ className, ...rest }: TeamMetricTitleProps) {
  return (
    <div className="flex gap-1">
      <Typography
        {...rest}
        variant="doubleXl/bold"
        className={cn('leading-[22px] text-grey-900 first-letter:capitalize', className)}
      />
    </div>
  );
}
